import { useState, useEffect } from 'react';
import { useWindowSize } from 'react-use';

const useCalculateContentHeight = () => {
    const screenSize = useWindowSize();
    const [isPhone, setIsPhone] = useState<boolean>();

    useEffect(() => {
        if (screenSize.width) {
            setIsPhone(screenSize.width <= 480);
        }
    }, [screenSize.width]);

    return isPhone ? screenSize.height - 110 : "";
    // 50px bottom nav + 60px top nav
};

export default useCalculateContentHeight;
