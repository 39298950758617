import styles from "./CreateNewGroup.module.css";
import { useState } from "react";
import { usePostNewGroup } from "../../customHooks/usePostNewGroup";
import AddContactsModal from "./AddContactsModal";
import { useNavigate } from "react-router-dom";
import Button from "../../components/elements/Button";
import { ReactComponent as ArrowBackIcon } from "../../components/icons/ArrowBackIcon.svg";

function CreateNewGroup() {
  const [groupName, setGroupName] = useState<string>("");
  const [groupMembers, setGroupMembers] = useState<string[]>([]);
  const [addContactsView, setAddContactsView] = useState<boolean>(true);

  const navigate = useNavigate();

  const { mutate, isLoading: isLoadingPostGroup, isError } = usePostNewGroup();
  //TODO handle error
  const handleAddContactsClick = (selectedContacts: string[]) => {
    setGroupMembers(selectedContacts);
    setAddContactsView(false);
  };

  const onArrowBackClick = () => {
    navigate("/Community/Groups");
  };

  return (
    <>
      <AddContactsModal
        onArrowBackClick={onArrowBackClick}
        currentMembers={[]}
        handleAddClick={handleAddContactsClick}
        addButtonText="Add contacts"
        display={addContactsView}
      />

      <div className={styles.groupNameContainer}>
        <div
          className={styles.arrowBack}
          onClick={() => setAddContactsView(true)}
        >
          <ArrowBackIcon />
        </div>

        <div className={styles.content}>
          <input
            placeholder="Group name..."
            className={styles.input}
            onChange={(e) => setGroupName(e.target.value)}
          />
          <Button
            handleClick={() =>
              mutate({
                groupName: groupName,
                groupMembers: groupMembers,
              })
            }
            disabled={isLoadingPostGroup}
            width="250px"
            buttonText={isLoadingPostGroup ? "Loading..." : "Create new group"}
          />
        </div>
      </div>
    </>
  );
  // TODO handle error
}

export default CreateNewGroup;
