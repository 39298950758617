import { useMutation } from "react-query";
import { apiClient } from "../../apiClient";

export const useRemoveContactFromGroup = () => {
  async function removeContactFromGroup({
    idToRemove,
    groupId,
  }: {
    idToRemove: string;
    groupId: string;
  }) {
    try {
      const body = { DeletedGroupMembers: [idToRemove] };
      await apiClient.post(`groups/${groupId}/members/bulk`, body);
    } catch (error) {
      console.error("Error removing contact from group, error: ", error);
      throw error;
    }
  }

  return useMutation(removeContactFromGroup);
};
