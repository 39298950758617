import { useMutation } from "react-query";
import { apiClient } from "../../apiClient";

export const useEditGroupName = () => {
  async function patchGroupname({
    newName,
    groupId,
  }: {
    newName: string;
    groupId: string;
  }) {
    try {
      const body = { Name: newName };
      await apiClient.patch(`groups/${groupId}`, body);
    } catch (error) {
      console.error("Error patching group name: ", error);
      throw error;
    }
  }

  return useMutation(patchGroupname);
};
