import "./TypeComment.css";
import { useUserPreferencesData } from "../customHooks/useUserPreferencesData";
import MiniAvatar from "../components/elements/MiniAvatar";
import SizeAdjustableTextArea from "./reusableComponents/SizeAdjustableTextArea";
import { ReactComponent as SendIcon } from "../components/icons/SendIcon.svg";
import { useState } from "react";
import { DiscussionType } from "../types/DiscussionType";
import { usePostComment } from "../customHooks/usePostComment";
import { RecipientType } from "./types/RecipientType";

interface TypeCommentProps {
  storyId: string;
  storyDateId: string;
  discussionType: DiscussionType;
  recipientId?: string;
}

function TypeComment({
  storyId,
  storyDateId,
  discussionType,
  recipientId,
}: TypeCommentProps) {
  const [commentValue, setCommentValue] = useState<string>("");
  const { data: userData } = useUserPreferencesData();

  const { mutate, isLoading: isLoadingPostComment } = usePostComment({
    storyId: storyId,
    storyDateId: storyDateId,
    discussionType: discussionType,
    recipientId: recipientId,
    userAvatar: userData?.Avatar || "",
    senderUserName: userData?.UserName || "",
    senderName: userData?.Name || "",
    setCommentValue: setCommentValue,
  });

  return (
    <div className="typeComment__div">
      <MiniAvatar
        src={userData?.Avatar ? userData.Avatar : ""}
        style={{ marginRight: "10px" }}
        name={userData?.Name ?? undefined}
        userName={userData?.UserName ?? undefined}
        characterType={RecipientType.User}
        height="32px"
      />
      <SizeAdjustableTextArea
        value={commentValue}
        setValue={setCommentValue}
        isLoading={isLoadingPostComment}
      >
        <button
          onClick={() => mutate(commentValue)}
          disabled={isLoadingPostComment || commentValue.length === 0}
          className="typeCommnet__sendButton"
        >
          <SendIcon
            fill={
              isLoadingPostComment || commentValue.length === 0
                ? "#81bca8"
                : "#0F5940"
            }
          />
        </button>
      </SizeAdjustableTextArea>
    </div>
  );
}

export default TypeComment;
