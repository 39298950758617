import globalStyles from "../components/global.module.css";
import { useGetDiscussionsActivities } from "../customHooks/useGetDiscussionsActivities";
import CommentActivity from "./CommentActivity";
import { InView } from "react-intersection-observer";
import MiniAvatar from "../components/elements/MiniAvatar";
import { CircularProgress } from "@mui/material";
import { Fragment, useState } from "react";
import { Link } from "react-router-dom";

interface ActivitiesDiscussionsProps {
  discussionType: "public" | "private";
}

function ActivitiesDiscussions({ discussionType }: ActivitiesDiscussionsProps) {
  const [triggeringFetchIds, setTriggeringFetchIds] = useState<string[]>([]);

  const {
    data,
    isLoading,
    isError,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useGetDiscussionsActivities({ discussionType });
  if (isLoading) {
    return (
      <div className={globalStyles.messageInfo}>
        <CircularProgress />
      </div>
    );
  }

  const fetchMoreData = (inView: boolean, id: string) => {
    if (
      inView &&
      hasNextPage &&
      !isFetchingNextPage &&
      !triggeringFetchIds.includes(id)
    ) {
      fetchNextPage();
      setTriggeringFetchIds((prevIds) => [...prevIds, id]);
    }
  };

  const hasActivities = data?.pages.some(
    (page) => page.data.activities.length > 0
  );

  return (
    <div>
      {!hasActivities ? (
        <div className={globalStyles.messageInfo}>
          There are no activities to display.
        </div>
      ) : (
        <>
          {data?.pages.map((page) =>
            page.data.activities.map((activity, index) => (
              <Fragment key={activity.newestMessage.id + "fragment"}>
                {index % 5 === 2 &&
                  !triggeringFetchIds.includes(activity.story.id) && (
                    <InView
                      onChange={(inView) =>
                        fetchMoreData(inView, activity.story.id)
                      }
                    />
                  )}
                <CommentActivity
                  imgSrc={activity.story.coverUrl}
                  headline={activity.story.headline}
                  newestMessage={activity.newestMessage.content}
                  key={activity.newestMessage.id}
                  storyDateId={activity.story.dateId}
                  storyId={activity.story.id}
                  newestMessageRecipientId={
                    activity.recipients.length > 0
                      ? activity.recipients[0].id
                      : "World"
                  }
                  newestMessageRecipientType={
                    activity.recipients.length > 0
                      ? activity.recipients[0].recipientType
                      : "World"
                  }
                >
                  {activity.recipients.length > 0 &&
                    activity.recipients.map((recipient) => (
                      <Link
                        to={`/News/${activity.story.dateId}/${activity.story.id}/true/${recipient.id}/${recipient.recipientType}`}
                        className={globalStyles.removeLinkDecoration}
                        key={recipient.id + "link"}
                      >
                        <div style={{ position: "relative" }}>
                          <MiniAvatar
                            key={recipient.id + "avatar"}
                            style={{ marginRight: "12px" }}
                            name={recipient.name}
                            userName={recipient.userName}
                            characterType={recipient.recipientType}
                            src={recipient.avatar}
                          />
                          {recipient.unreadMessagesCount > 0 && (
                            <div
                              className={globalStyles.avatarNotificationDot}
                            ></div>
                          )}
                        </div>
                      </Link>
                    ))}
                </CommentActivity>
              </Fragment>
            ))
          )}
          {isError ? (
            <div className={globalStyles.messageInfo}>
              Something went wrong. Please try again later.
            </div>
          ) : isFetchingNextPage ? (
            <div className={globalStyles.bottomLoader}>
              <CircularProgress />
            </div>
          ) : null}
        </>
      )}
    </div>
  );
}
export default ActivitiesDiscussions;
