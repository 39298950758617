import { apiClient } from "../apiClient";
import { useMutation, useQueryClient } from "react-query";

interface useAddNewMembersToGroupProps {
  groupId: string;
}
export const useAddNewMembersToGroup = ({
  groupId,
}: useAddNewMembersToGroupProps) => {
  const queryClient = useQueryClient();
  async function postNewMembers(newMembersArray: string[]) {
    const body = {
      NewGroupMembers: newMembersArray,
    };
    await apiClient.post(`groups/${groupId}/members/bulk`, body);
  }

  return useMutation(postNewMembers, {
    onSuccess: () => queryClient.invalidateQueries(["group", groupId]),
  });
};
