import { apiClient } from "../../apiClient";
import { useMutation } from "react-query";

export const useReadNotification = () => {
  async function postRead(id: string) {
    try {
      apiClient.post(`notifications/${id}/read`);
    } catch (error) {
      throw new Error("Error marking notification as read");
    }
  }

  return useMutation(postRead);
};
