import styles from "./BigScreenError.module.css";
import Logo from "./icons/paper planes final_webpage transparent.png";

function BigScreenError() {
  return (
    <div className={styles.background}>
      <div className={styles.container}>
        <h1>Our app is currently optimized only for mobile devices. </h1>
        <h2>
          Please switch to a mobile device to access all features and enjoy the
          best experience!
        </h2>
        <div className={styles.logoDiv}>
          <img src={Logo} alt="Paper Planes logo" className={styles.logo} />
        </div>
      </div>
    </div>
  );
}

export default BigScreenError;
