import globalstyles from "./global.module.css";
import SetUserPreferences from "../userPreferences/SetUserPreferences";
import Feed from "../feed/Feed";
import AnimationScreen from "./AnimationScreen";
import {
  CorsError,
  useUserPreferencesData,
} from "../customHooks/useUserPreferencesData";

function HomePage() {
  const { data, isLoading, isError, error } = useUserPreferencesData();

  const userHasPreferences = !!(data && data.FavoriteCategories.length > 0);

  return (
    <>
      {isLoading ? (
        <AnimationScreen
          loadingText={"LOADING..."}
          showTopMenu={userHasPreferences}
        />
      ) : error instanceof CorsError ? (
        <div className={globalstyles.centralMessage}>
          <h1>Oops! Something went wrong.</h1>
          <p>
            We are aware of the issue and our engineers are working hard to fix
            it. Please try again later.
          </p>
        </div>
      ) : isError ? (
        <div className={globalstyles.centralMessage}>
          <h1>Oops! Something went wrong.</h1>
          <p>Please try again later.</p>
        </div>
      ) : userHasPreferences ? (
        <Feed />
      ) : (
        <div
          style={{
            position: "absolute",
            top: "0px",
            right: "0px",
            bottom: "0px",
            left: "0px",
          }}
        >
          <SetUserPreferences />
        </div>
      )}
    </>
  );
}

export default HomePage;
