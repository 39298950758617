import styles from "./ExpandableSearchInput.module.css";
import { ReactComponent as SearchIcon } from "../../components/icons/SearchIcon.svg";
import { SetStateAction } from "react";

interface ExpandableSearchInputProps {
  isSearchActive: boolean;
  setSearchActive: React.Dispatch<SetStateAction<boolean>>;
  searchPhrase: string;
  setSearchPhrase: React.Dispatch<SetStateAction<string>>;
  iconSize?: string;
  iconStrokeWidth?: string;
}

function ExpandableSearchInput(props: ExpandableSearchInputProps) {
  return (
    <div
      className={`${styles.box} ${
        props.isSearchActive ? styles.boxActive : null
      }`}
    >
      <input
        className={`${styles.input}
          ${
            props.isSearchActive ? styles.input__active : styles.input__hidden
          }`}
        placeholder="Search..."
        value={props.searchPhrase}
        onChange={(e) => props.setSearchPhrase(e.target.value)}
      />

      <div>
        <SearchIcon
          height={props.iconSize ? props.iconSize : "30px"}
          width={props.iconSize ? props.iconSize : "30px"}
          stroke={props.isSearchActive ? "#0F7D57" : "#121212"}
          strokeWidth={props.iconStrokeWidth ? props.iconStrokeWidth : "1.5"}
          onClick={() => props.setSearchActive(true)}
        />
      </div>
    </div>
  );
}

export default ExpandableSearchInput;
