import { AdvancedRealTimeChart } from 'react-ts-tradingview-widgets';
import "./TradingCard.css";
import { useMemo } from 'react';


interface TradingCardProps {
    Id: number;
    StockSymbol: string;
}

function TradingCard(props: TradingCardProps) {
    const chart = useMemo(() => <AdvancedRealTimeChart theme="dark"  symbol={props.StockSymbol} enable_publishing={false} details={false} hotlist={false} calendar={false} hide_top_toolbar={true} hide_side_toolbar={true} allow_symbol_change={false} hide_legend={false} interval='D' autosize></AdvancedRealTimeChart>, [props.StockSymbol]);
    return (
        <div className="trading" key={props.Id}>
            {chart}
            <div style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'transparent',
                zIndex: 2
            }}></div>
        </div>
    );
}
export default TradingCard;