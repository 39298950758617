import { AxiosResponse } from "axios";
import { apiClient } from "../../apiClient";
import { CardTypes, ContentResponse } from "../types/ContentResponse";
import { CardTypesEnum } from "../types/CardTypesEnum";
import { HeadlineStoryCardApiType } from "../types/HeadlineStoryCardApiType";
import { StockCardApiType } from "../types/StockCardApiType";
import { SummaryStoryCardApiType } from "../types/SummaryStoryCardApiType";
import { TwittCardApiType } from "../types/TwittCardApiType";
import { useQuery } from "react-query";

interface UseFetchSingleStoryProps {
  storyDateId?: string;
  storyId?: string;
}

interface SingleStoryResponse {
  Story: ContentResponse;
}

export const useFetchSingleStory = ({
  storyDateId,
  storyId,
}: UseFetchSingleStoryProps) => {
  async function fetchStory(): Promise<ContentResponse> {
    try {
      const response: AxiosResponse<SingleStoryResponse> = await apiClient.get(
        `/stories/${storyDateId}/${storyId}`
      );
      const data = response.data.Story;
      const newContent: ContentResponse = {
        Categories: data.Categories,
        DateId: data.DateId,
        Language: data.Language,
        Id: data.Id,
        Bookmark: data.Bookmark,
        Favorite: data.Favorite,
        Likes: data.Likes,
        Source: data.Source,
        Cards: data.Cards.map((card: CardTypes) => {
          if (card?.Type === CardTypesEnum.HeadlineStoryCard) {
            return card as HeadlineStoryCardApiType;
          } else if (card?.Type === CardTypesEnum.SummaryStoryCard) {
            return card as SummaryStoryCardApiType;
          } else if (card?.Type === CardTypesEnum.StockCard) {
            return card as StockCardApiType;
          } else if (card?.Type === CardTypesEnum.TwittCard) {
            return card as TwittCardApiType;
          } else {
            return null;
          }
        }).filter((card: CardTypes) => card !== null),
      };
      return newContent;
    } catch (error) {
      console.error("Error fetching single story: ", error);
      throw error;
    }
  }

  return useQuery(`fetchStory${storyId}`, fetchStory, {
    enabled: !!storyDateId && !!storyId,
  });
};
