import { CircularProgress } from '@mui/material';
import './SizeAdjustableTextArea.css'
import { useEffect, useRef, ChangeEvent, ReactElement, SetStateAction } from 'react';

interface SizeAdjustableTextAreaProps {
    children: ReactElement;
    value: string;
    setValue: React.Dispatch<SetStateAction<string>>;
    isLoading?: boolean;
}

function SizeAdjustableTextArea(props: SizeAdjustableTextAreaProps) {

    const textareaRef = useRef<HTMLTextAreaElement>(null);

    const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        props.setValue(event.target.value);
    }

    useEffect(() => {
        const textarea = textareaRef.current;
        if (textarea) {
            if (props.value) {
                textarea.style.height = `${textarea.scrollHeight}px`
            }
            else {
                textarea.style.height = `32px`
            }
        }
    }, [props.value])

    return (
        <div className="textareaDiv">
            <textarea
                ref={textareaRef}
                placeholder='Type your comment'
                value={props.value}
                onChange={handleChange}
                className='comment__input'
            />
            <div>
                {props.isLoading ?
                    <div>
                        <CircularProgress size={15} />
                    </div>
                    : null}
                {props.children}
            </div>
        </div>
    )
}

export default SizeAdjustableTextArea