import styles from "./UserPreferences.module.css";
import globalStyles from "../components/global.module.css";
import { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import CategoriesSelector from "./CategoriesSelector";
import LanguageSelector from "./LanguageSelector";
import { useUserPreferencesData } from "../customHooks/useUserPreferencesData";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useUpdateUserPreferences } from "./hooks/useUpdateUserPreferences";
import { ReactComponent as ArrowBackIcon } from "../components/icons/ArrowBackIcon.svg";

function SetUserPreferences() {
  const {
    data: currentPreferences,
    isLoading: isLoadingGetPreferences,
    isError: isErrorGetPreferences,
  } = useUserPreferencesData();

  const { mutate: sendPreferencesUpdate, isLoading: isUpdatingPreferences } =
    useUpdateUserPreferences();

  const [preferredLanguages, setPreferredLanguages] = useState<string[]>(
    currentPreferences?.Languages || []
  );
  const [preferredCategories, setPreferredCategories] = useState<string[]>(
    currentPreferences?.FavoriteCategories || []
  );

  const [languageSelectorScreen, setLanguageSelectorScreen] =
    useState<boolean>(true);

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  if (isLoadingGetPreferences) {
    <CircularProgress />;
  } else if (isErrorGetPreferences) {
    <p>Something went wrong, please try again later</p>;
  }

  const handlePreferencesUpdate = () => {
    sendPreferencesUpdate(
      {
        preferredCategories: preferredCategories,
        preferredLanguages: preferredLanguages,
        requestType:
          currentPreferences && currentPreferences.FavoriteCategories.length > 0
            ? "update"
            : "initial",
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries("getUserPreferences");
          navigate("/");
        },
        onError: (error) => {
          toast.error(
            "Something went wrong, we couldn't update your preferences",
            {
              position: "top-center",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              draggable: true,
              theme: "colored",
            }
          );
        },
      }
    );
  };

  if (isLoadingGetPreferences) {
    return (
      <div className={globalStyles.centralMessage}>
        <CircularProgress />
      </div>
    );
  }

  if (isErrorGetPreferences) {
    return (
      <div className={globalStyles.messageInfo}>
        <p>Something went wrong, please refresh the page.</p>
      </div>
    );
  }

  if (currentPreferences === undefined) {
    return null;
  }

  return (
    <div className={styles.mainContainer}>
      <div className={styles.topNav}>
        {languageSelectorScreen === false && (
          <div onClick={() => setLanguageSelectorScreen(true)}>
            <ArrowBackIcon />
          </div>
        )}
      </div>
      <div className={styles.contentContainer}>
        {languageSelectorScreen ? (
          <div className={styles.languagesContainer}>
            <LanguageSelector
              preferredLanguages={preferredLanguages}
              setPreferredLanguages={setPreferredLanguages}
              setLanguageSelectorScreen={setLanguageSelectorScreen}
            />
          </div>
        ) : (
          <CategoriesSelector
            preferredCategories={preferredCategories}
            setPreferredCategories={setPreferredCategories}
            handleSubmitButton={handlePreferencesUpdate}
            isUpdatingPreferences={isUpdatingPreferences}
          />
        )}
      </div>
    </div>
  );
}

export default SetUserPreferences;
