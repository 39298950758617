import { useMutation } from "react-query";
import { apiClient } from "../../apiClient";

export const useLeaveGroup = () => {
  async function leaveGroup(groupId: string) {
    try {
      await apiClient.post(`groups/${groupId}/leave`);
    } catch (error) {
      console.error("Error leaving group: ", error);
      throw error;
    }
  }

  return useMutation(leaveGroup);
};
