import styles from "./HeaderFeed.module.css";
import { ReactComponent as Logo } from "../components/icons/PaperPlanes.svg";
import { ReactComponent as DiscussionsIcon } from "../components/icons/DiscussionsIcon.svg";
import ExpandableSearchInput from "../components/elements/ExpandableSearchInput";
import { useState, useRef, useEffect } from "react";
import GlobalSearchResultsList from "../components/elements/GlobalSearchResultsList";
import { useDebounce } from "react-use";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { usePendingMessagesContext } from "../discussions/context/PendingMessagesContext";

function HeaderFeed() {
  const [searchPhrase, setSearchPhrase] = useState("");
  const [searchActive, setSearchActive] = useState(false);
  const [debouncedSearchPhrase, setDebouncedSearchPhrase] = useState("");

  const { pendingMessages } = usePendingMessagesContext();

  const [path, setPath] = useState<string>("");

  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    const pathParts = pathname.split("/");
    setPath(pathParts[1]?.toLowerCase());
  }, [pathname]);

  useDebounce(() => setDebouncedSearchPhrase(searchPhrase), 500, [
    searchPhrase,
  ]);

  const searchRef = useRef<HTMLDivElement>(null);
  const resultsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (
        searchRef.current &&
        !searchRef.current.contains(e.target as Node) &&
        resultsRef.current &&
        !resultsRef.current.contains(e.target as Node)
      ) {
        setSearchActive(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className={styles.headerFeed}>
        <div className={styles.logo}>
          <Logo />
        </div>
        <div className={styles.iconsRight}>
          <div ref={searchRef}>
            <ExpandableSearchInput
              isSearchActive={searchActive}
              setSearchActive={setSearchActive}
              searchPhrase={searchPhrase}
              setSearchPhrase={setSearchPhrase}
              iconSize="34px"
              iconStrokeWidth="1.8"
            />
          </div>
          <Link to="/Activities">
            <div className={styles.discussionsBox}>
              <DiscussionsIcon
                className={styles.discussionsIcon}
                fill={`${path === "activities" ? "#0F7D57" : "#121212"}`}
              />
              {pendingMessages && <div className={styles.discussionsDot}></div>}
            </div>
          </Link>
        </div>
      </div>
      <div ref={resultsRef}>
        {searchActive ? (
          <GlobalSearchResultsList
            searchPhrase={debouncedSearchPhrase}
            setSearchActive={setSearchActive}
          />
        ) : null}
      </div>
    </>
  );
}

export default HeaderFeed;
