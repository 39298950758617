import styles from "./UserPreferences.module.css";
import globalStyles from "../components/global.module.css";
import CircularProgress from "@mui/material/CircularProgress";
import { useGetAllLanguages } from "./hooks/useGetAllLanguages";
import SelectableItem from "./SelectableItem";
import { SetStateAction } from "react";

interface LanguageSelectorProps {
  preferredLanguages: string[];
  setPreferredLanguages: React.Dispatch<React.SetStateAction<string[]>>;
  setLanguageSelectorScreen: React.Dispatch<SetStateAction<boolean>>;
}

function LanguageSelector(props: LanguageSelectorProps) {
  const { data: languages, isLoading, isError } = useGetAllLanguages();

  const toggleLanguageSelection = (language: string) => {
    props.setPreferredLanguages((prevPreferredLanguages) =>
      prevPreferredLanguages.includes(language)
        ? prevPreferredLanguages.filter(
            (selectedLanguage) => selectedLanguage !== language
          )
        : [...prevPreferredLanguages, language]
    );
  };

  if (isLoading) {
    return (
      <div className={globalStyles.messageInfo}>
        <CircularProgress />
      </div>
    );
  }

  if (isError) {
    return (
      <div className={globalStyles.messageInfo}>
        Something went wrong, please try again later.
      </div>
    );
  }

  if (!languages) {
    return null;
  }

  return (
    <div>
      <h3 className={styles.sectionHeader}>Language Preferences:</h3>
      <h4 className={styles.sectionDescription}>
        Personalize your feed with your preferred languages for a better
        experience.
      </h4>
      <div className={styles.languageOptionsContainer}>
        {languages?.map((language, index) => (
          <div className={styles.itemContainer} key={`${language} ${index}`}>
            <SelectableItem
              Name={language.Language}
              Icon={language.Icon}
              isSelected={props.preferredLanguages.includes(
                language.LanguageCode
              )}
              handleClick={() => toggleLanguageSelection(language.LanguageCode)}
              key={language.Language}
            />
          </div>
        ))}
      </div>
      <div className={styles.buttonContainer}>
        <button
          className={styles.submitButton}
          onClick={() => props.setLanguageSelectorScreen(false)}
          disabled={props.preferredLanguages.length < 1}
        >
          Next
        </button>
      </div>
    </div>
  );
}
export default LanguageSelector;
