import { ReactComponent as AddPictureIcon } from "../../components/icons/AddPictureIcon.svg";
import styles from "./CreateNewGroupButton.module.css";

function CreateNewGroupButton() {
  return (
    <button className={styles.button}>
      <AddPictureIcon />
      <div className={styles.text}>
        <span className={styles.name}>Create Group</span>
        <span className={styles.description}>Tap to create group</span>
      </div>
    </button>
  );
}

export default CreateNewGroupButton;
