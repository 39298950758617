import { useParams } from "react-router-dom";
import NotFoundPage from "../components/NotFoundPage";
import ActivitiesDiscussions from "./ActivitiesDiscussions";

function ActivitiesDiscussionsWrapper() {
  const { accessType } = useParams();

  return (
    <>
      {accessType?.toLowerCase() === "public" ? (
        <ActivitiesDiscussions discussionType="public" />
      ) : accessType?.toLowerCase() === "private" ? (
        <ActivitiesDiscussions discussionType="private" />
      ) : (
        <NotFoundPage />
      )}
    </>
  );
}

export default ActivitiesDiscussionsWrapper;
