import { apiClient, msalInstance } from "../apiClient";
import { useQuery } from "react-query";
import { useIsAuthenticated } from "@azure/msal-react";
import { AxiosError, AxiosResponse } from "axios";
import axios from "axios";

interface UserPreferencesResponse {
  FavoriteCategories: string[];
  Languages: string[];
  UserName: string;
  Name: string | null;
  Avatar: string;
}

export class CorsError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "CorsError";
  }
}

async function fetchUserPreferences(): Promise<UserPreferencesResponse> {
  try {
    const response: AxiosResponse<UserPreferencesResponse> =
      await apiClient.get("userpreferences");
    return response.data;
  } catch (error) {
    if (
      (error as AxiosError).response &&
      (error as AxiosError).response?.status === 401
    ) {
      msalInstance.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
    } else if (axios.isAxiosError(error) && error.message === "Network Error") {
      throw new CorsError("CorsError");
    }
    throw error;
  }
}

export const useUserPreferencesData = () => {
  const isAuthenticated = useIsAuthenticated();

  return useQuery("getUserPreferences", fetchUserPreferences, {
    staleTime: Infinity, // Data never goes stale
    cacheTime: 1000 * 60 * 60 * 24, // Data is cached for 24 hours
    enabled: isAuthenticated,
    refetchOnWindowFocus: false,
  });
};
