import { apiClient } from "../apiClient";
import { useQuery } from "react-query";
import { AxiosResponse } from "axios";
import { RecipientType } from "../discussions/types/RecipientType";

interface UseGetPostActivitiesProps {
  storyDateId: string;
  storyId: string;
  display: boolean;
}

export interface ActiveRecipientsResponse {
  id: string;
  recipientType: RecipientType;
  lastMessage?: string;
  avatar?: string;
  name?: string;
  userName: string;
  unreadMessagesCount: number;
}

export interface GetPostActivitiesResponse {
  activity: {
    story: {
      id: string;
      dateId: string;
    };
    activeRecipients: ActiveRecipientsResponse[];
  };
}

export const useGetPostActivities = ({
  storyDateId,
  storyId,
  display,
}: UseGetPostActivitiesProps) => {
  const fetchPostActivities = async (): Promise<GetPostActivitiesResponse> => {
    try {
      const response: AxiosResponse<GetPostActivitiesResponse> =
        await apiClient.get(
          `/discussions/${storyDateId}/${storyId}/activities`
        );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  return useQuery(`getPostActivities${storyId}`, fetchPostActivities, {
    enabled: display,
    refetchOnWindowFocus: false,
  });
};
