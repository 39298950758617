import { useMutation } from "react-query";
import { apiClient } from "../../apiClient";
import { RecipientType } from "../types/RecipientType";

interface UseReadMessageProps {
  storyDateId: string;
  storyId: string;
  recipientType: RecipientType | null;
  recipientId: string | null;
}

export const useReadMessage = ({
  storyDateId,
  storyId,
  recipientType,
  recipientId,
}: UseReadMessageProps) => {
  const endpoints: Record<RecipientType, string> = {
    [RecipientType.User]: `discussions/${storyDateId}/${storyId}/users/${recipientId}/messages/read`,
    [RecipientType.Group]: `discussions/${storyDateId}/${storyId}/groups/${recipientId}/messages/read`,
  };

  const endpoint = recipientType ? endpoints[recipientType] : "";

  async function sendPostReadMessage() {
    if (recipientId && recipientType) {
      try {
        await apiClient.post(endpoint);
      } catch (error) {
        console.error("Error sending post read message:", error);
        throw error;
      }
    }
  }

  return useMutation(sendPostReadMessage);
};
