import { QueryFunctionContext, useInfiniteQuery } from "react-query";
import { apiClient } from "../../apiClient";
import { AxiosResponse } from "axios";
import { useState } from "react";
import { NotificationType } from "../types/NotificationType";
import { SupportNotificationDataType } from "../types/SupportNotificationDataType";

interface GetNotificationsRespone {
  notifications: NotificationType<SupportNotificationDataType>[];
}
// append NotificationType to handle different notifications types

interface QueryContextProps {
  lastModified: string;
}

interface QueryResponseData {
  data: GetNotificationsRespone;
  lastModified: string;
}
export const useGetNotifications = () => {
  const [enableFetch, setEnableFetch] = useState<boolean>(true);

  const fetchNotifications = async (
    context: QueryFunctionContext<string, QueryContextProps>
  ): Promise<QueryResponseData> => {
    const response: AxiosResponse<GetNotificationsRespone> =
      await apiClient.get("notifications", {
        params: {
          type: "read",
          number: 10,
          lastModified: context.pageParam?.lastModified,
        },
      });

    const notifications = response.data.notifications;
    const lastModified =
      notifications.length > 0
        ? notifications[notifications.length - 1].lastModified
        : "";

    return {
      data: response.data,
      lastModified,
    };
  };

  return useInfiniteQuery("getNotifications", fetchNotifications, {
    getNextPageParam: (lastPage) => {
      if (lastPage && lastPage.data.notifications.length < 10) {
        return undefined;
      } else if (lastPage && lastPage.lastModified) {
        return {
          lastModified: lastPage.lastModified,
        };
      } else {
        return undefined;
      }
    },
    enabled: enableFetch,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    onSuccess: () => setEnableFetch(false),
  });
};
