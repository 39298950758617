import { apiClient } from "../../apiClient";
import { useMutation } from "react-query";

export interface UseUpdateUserPreferencesProps {
  preferredCategories: string[];
  preferredLanguages: string[];
  requestType: "initial" | "update";
}

export const useUpdateUserPreferences = () => {
  const updateUserPreferences = async ({
    preferredCategories,
    preferredLanguages,
    requestType,
  }: UseUpdateUserPreferencesProps) => {
    const requestData = {
      FavoriteCategories: preferredCategories,
      Languages: preferredLanguages,
    };

    try {
      if (requestType === "initial") {
        await apiClient.put("userpreferences", requestData);
      } else {
        await apiClient.patch("userpreferences", requestData);
      }
    } catch (error) {
      console.error("Error updating user preferences:", error);
      throw error;
    }
  };

  return useMutation(updateUserPreferences);
};
