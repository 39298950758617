import { RecipientType } from "../../discussions/types/RecipientType";
import "./MiniAvatar.css";
import { CSSProperties, useMemo } from "react";
import defaultProfilePicture from "../../profile/defaultProfilePicture.jpg";

interface MiniAvatarDiscussionsProps {
  src?: string;
  onClick?: () => void;
  style?: CSSProperties;
  height?: string;
  className?: string;
  name?: string;
  userName?: string;
  characterType: RecipientType;
}

function MiniAvatar(props: MiniAvatarDiscussionsProps) {
  const size = props.height ?? "30px";
  const avatarStyle: CSSProperties = useMemo(
    () => ({
      ...props.style,
      height: size,
      width: size,
      borderRadius: size,
      flexShrink: "0",
      backgroundColor:
        props.characterType === RecipientType.User
          ? "var(--green-avatar)"
          : "var(--blue-avatar)",
      color: props.characterType === RecipientType.User ? "#000" : "#fff",
      fontSize: parseInt(size) * 0.4,
    }),
    [props.characterType, props.style, size]
  );

  const generateAvatarLetters = (
    name: string | undefined,
    userName: string | undefined
  ) => {
    if (!!name) {
      const nameArr = name.split(" ");
      return (
        nameArr[0].charAt(0).toUpperCase() +
        (nameArr[1]?.charAt(0).toUpperCase() ??
          nameArr[0].charAt(1).toUpperCase() ??
          "")
      );
    } else if (!!userName) {
      const userNameArr = userName.split(" ");
      return (
        userNameArr[0].charAt(0).toUpperCase() +
        (userNameArr[0].charAt(1).toUpperCase() ?? "")
      );
    }
  };

  return (
    <>
      {props.src ? (
        <div
          className={"miniAvatar"}
          onClick={props.onClick}
          style={avatarStyle}
        >
          <img src={props.src} alt="profilePicture" />
        </div>
      ) : !!props.name || !!props.userName ? (
        <div
          className="defaultAvatar"
          onClick={props.onClick}
          style={avatarStyle}
        >
          {generateAvatarLetters(props.name, props.userName)}
        </div>
      ) : (
        <div
          className={"miniAvatar"}
          onClick={props.onClick}
          style={avatarStyle}
        >
          <img src={defaultProfilePicture} alt="profilePicture" />
        </div>
      )}
    </>
  );
}

export default MiniAvatar;
