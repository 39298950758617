import {
  createContext,
  useContext,
  useState,
  SetStateAction,
  ReactNode,
} from "react";
import { NotificationType } from "../types/NotificationType";
import { SupportNotificationDataType } from "../types/SupportNotificationDataType";

interface NewNotificationsContextType {
  newNotifications: NotificationType<SupportNotificationDataType>[];
  setNewNotifications: React.Dispatch<
    SetStateAction<NotificationType<SupportNotificationDataType>[]>
  >;
  unreadNotificationsCount: number;
  setUnreadNotificationsCount: React.Dispatch<SetStateAction<number>>;
}

interface NewNotificationsContextProviderProps {
  children: ReactNode;
}

const NewNotificationsContext =
  createContext<NewNotificationsContextType | null>(null);

export const NewNotificationsContextProvider = ({
  children,
}: NewNotificationsContextProviderProps) => {
  const [newNotifications, setNewNotifications] = useState<
    NotificationType<SupportNotificationDataType>[]
  >([]);
  const [unreadNotificationsCount, setUnReadNotificationsCount] =
    useState<number>(0);

  return (
    <NewNotificationsContext.Provider
      value={{
        newNotifications: newNotifications,
        setNewNotifications: setNewNotifications,
        unreadNotificationsCount: unreadNotificationsCount,
        setUnreadNotificationsCount: setUnReadNotificationsCount,
      }}
    >
      {children}
    </NewNotificationsContext.Provider>
  );
};

export function useNewNotificationsContext() {
  const context = useContext(NewNotificationsContext);
  if (!context) {
    throw new Error(
      "useNewNotificationsContext must be used within a NewNotificationContextProvider"
    );
  }
  return context;
}
