import { useQuery } from "react-query";
import { apiClient } from "../apiClient";
import { MemberBasicInfoType } from "../types/MemberBasicInfoType";
import { AxiosResponse } from "axios";

interface GroupDetailsResponse {
  group: {
    id: string;
    name: string;
    avatar: string;
    groupOwner: MemberBasicInfoType;
    groupMembers: MemberBasicInfoType[];
  };
  isOwner: boolean;
}

interface UseGetGroupDetailsProps {
  groupId: string;
}

export const useGetGroupDetails = ({ groupId }: UseGetGroupDetailsProps) => {
  async function getGroupDetails(): Promise<GroupDetailsResponse> {
    try {
      const response: AxiosResponse<GroupDetailsResponse> = await apiClient.get(
        `groups/${groupId}`
      );
      return response.data;
    } catch (error) {
      throw new Error("Error fetching group details");
    }
  }
  return useQuery(["group", groupId], getGroupDetails);
};
