import { useMutation } from "react-query";
import { apiClient } from "../../apiClient";

export const useChangeGroupOwnership = () => {
  async function editGroupOwner({
    groupId,
    newOwnerId,
  }: {
    groupId: string;
    newOwnerId: string;
  }) {
    try {
      const body = { GroupOwner: newOwnerId };
      await apiClient.patch(`groups/${groupId}`, body);
    } catch (error) {
      console.error("Error changing group ownership, error: ", error);
      throw error;
    }
  }
  return useMutation(editGroupOwner);
};
