import "./Menu.css";
import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as HomeIcon } from "../components/icons/HomeIcon.svg";
import { ReactComponent as ContactIcon } from "../components/icons/ContactIcon.svg";
import { ReactComponent as BellIcon } from "../components/icons/BellIcon.svg";
import { ReactComponent as ProfileIcon } from "../components/icons/ProfileIcon.svg";
import { useNewNotificationsContext } from "../notifications/context/newNotificationContext";

function Menu() {
  const [active, setActive] = useState<string>("/");

  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    const pathParts = pathname.split("/");
    setActive(pathParts[1]?.toLowerCase());
  }, [pathname]);

  const { unreadNotificationsCount: unreadNotifications } =
    useNewNotificationsContext();

  return (
    <div className="menu">
      <div className="menu_icons">
        <Link to="/">
          <HomeIcon style={{ fill: active === "" ? "#0F7D57" : "#121212" }} />
        </Link>
        <Link to="/Community">
          <ContactIcon
            style={{ fill: active === "community" ? "#0F7D57" : "#121212" }}
          />
        </Link>
        <Link to="/Notifications">
          <div className="notificationButton">
            <BellIcon
              style={{
                fill: active === "notifications" ? "#0F7D57" : "#121212",
              }}
              className="bellIcon"
            />
            {unreadNotifications > 0 ? (
              <div className="notificationDot">
                <span>
                  {unreadNotifications < 99 ? unreadNotifications : "99+"}
                </span>
              </div>
            ) : null}
          </div>
        </Link>
        <Link to="/MyProfile">
          <ProfileIcon
            style={{ fill: active === "myprofile" ? "#0F7D57" : "#121212" }}
          />
        </Link>
      </div>
    </div>
  );
}

export default Menu;
