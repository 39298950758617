import { useMutation } from "react-query";
import { apiClient } from "../../apiClient";
import { RecipientType } from "../types/RecipientType";

interface DeleteMessageNotificationParams {
  storyDateId: string;
  storyId: string;
  callerType: RecipientType;
  callerId: string;
}

const sendDelete = async ({
  storyDateId,
  storyId,
  callerType,
  callerId,
}: DeleteMessageNotificationParams) => {
  try {
    const response = await apiClient.delete(
      `/notifications/discussions/${storyDateId}/${storyId}/${
        callerType === RecipientType.User ? "users" : "groups"
      }/${callerId}`
    );
    return response.status;
  } catch (error) {
    throw error;
  }
};

export const useDeleteNewMessagesNotifications = () => {
  return useMutation(sendDelete);
};
