import { useInfiniteQuery } from "react-query";
import { apiClient } from "../apiClient";
import { UserContactResponseType } from "../types/UserContactResponseType";
import { AxiosResponse } from "axios";

interface useGetMyContactsProps {
  endpoint: "supports" | "supporting";
  type: "Supporters" | "Supporting";
}

export interface GetContactsResponse {
  PageSize: number;
  PageNumber: number;
  Supporters?: UserContactResponseType[];
  Supporting?: UserContactResponseType[];
}

export const useGetMyContacts = ({ endpoint, type }: useGetMyContactsProps) => {
  async function fetchContacts({
    pageParam = 0,
  }): Promise<UserContactResponseType[] | undefined> {
    try {
      const response: AxiosResponse<GetContactsResponse> = await apiClient.get(
        `users/${endpoint}?PageNumber=${pageParam}`
      );
      const nextContacts = response?.data[type];
      return nextContacts;
    } catch (error) {
      throw error;
    }
  }

  return useInfiniteQuery(["contacts", type], fetchContacts, {
    getNextPageParam: (lastPage, pages) => {
      if (lastPage && lastPage.length < 30) {
        return undefined;
      } else if (lastPage && lastPage.length > 0) {
        return pages.length;
      }
      return undefined;
    },
    refetchOnMount: false,
  });
};
