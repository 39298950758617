import styles from "./ProfileAvatarSelector.module.css";
import AvatarCropperModal from "../components/AvatarCropperModal";
import defaultProfilePicture from "../profile/defaultProfilePicture.jpg";
import { useChangeUserAvatar } from "./hooks/useChangeUserAvatar";
import { useAvatarUpload } from "../customHooks/useAvatarUpload";

interface ProfileAvatarSelectorProps {
  currentPicSrc?: string;
}

function ProfileAvatarSelector({ currentPicSrc }: ProfileAvatarSelectorProps) {
  const { mutate: changeUserAvatar, isLoading: isChangingUserAvatar } =
    useChangeUserAvatar();

  const {
    inputRef,
    croppedPicRef,
    avatarSelectorModalOpen,
    newAvatarSrc,
    handleAddPic,
    handleImgChange,
    setCanvas,
    setAvatarSelectorModalOpen,
  } = useAvatarUpload({
    mutateFn: changeUserAvatar,
    invalidateQueryKey: "getUserPreferences",
  });

  return (
    <div className={styles.container}>
      <input
        type="file"
        ref={inputRef}
        accept="image/png, image/jpeg, image/jpg"
        onChange={handleImgChange}
        style={{ display: "none" }}
      />
      <AvatarCropperModal
        newAvatarSrc={newAvatarSrc}
        modalOpen={avatarSelectorModalOpen}
        croppedPicRef={croppedPicRef}
        setModalOpen={setAvatarSelectorModalOpen}
        handleSavePicture={setCanvas}
        isLoading={isChangingUserAvatar}
      />

      <div className={styles.imgContainer}>
        <img
          src={currentPicSrc ? currentPicSrc : defaultProfilePicture}
          alt="profilePicture"
        />
      </div>

      <button onClick={() => handleAddPic()} className={styles.uploadButton}>
        Upload new picture
      </button>
    </div>
  );
}

export default ProfileAvatarSelector;
