import styles from "./EditGroupNameDialog.module.css";
import { SetStateAction, useState } from "react";
import { useQueryClient } from "react-query";
import { useEditGroupName } from "../hooks/useEditGroupName";
import { toast } from "react-toastify";
import {
  errorToastOptions,
  successToastOptions,
} from "../../components/toastOptions";
import { CircularProgress } from "@mui/material";

interface EditGroupNameDialogProps {
  groupId: string;
  currentGroupName: string;
  setDialogWindowOpen: React.Dispatch<SetStateAction<boolean>>;
}

function EditGroupNameDialog(props: EditGroupNameDialogProps) {
  const [newGroupName, setNewGroupName] = useState<string>(
    props.currentGroupName
  );
  const queryClient = useQueryClient();

  const { mutate: mutateGroupName, isLoading: isLoadingGroupNameChange } =
    useEditGroupName();

  const handleEditGroupName = (newName: string, groupId: string) => {
    mutateGroupName(
      { newName, groupId },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["group", groupId]);
          toast.success(
            `Group renamed to ${newGroupName}`,
            successToastOptions
          );
          props.setDialogWindowOpen(false);
        },
        onError: (error) => {
          toast.error(
            "Something went wrong. We couldn't change the group name",
            errorToastOptions
          );
        },
      }
    );
  };

  return (
    <div className={styles.background}>
      <div className={styles.dialogBox}>
        <input
          autoFocus
          placeholder="New group name..."
          className={styles.input}
          value={newGroupName}
          onChange={(e) => setNewGroupName(e.target.value)}
        />

        <button
          className={`${styles.dialogButton} ${styles.blue}`}
          onClick={() => handleEditGroupName(newGroupName, props.groupId)}
          disabled={isLoadingGroupNameChange || newGroupName.length === 0}
        >
          {isLoadingGroupNameChange ? <CircularProgress size={14} /> : "Save"}
        </button>
        <button
          className={styles.dialogButton}
          onClick={() => props.setDialogWindowOpen(false)}
        >
          Cancel
        </button>
      </div>
    </div>
  );
}

export default EditGroupNameDialog;
