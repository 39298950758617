import { useQuery } from "react-query";
import { apiClient } from "../apiClient";
import { GetContactsResponse } from "./useGetMyContacts";
import { AxiosResponse } from "axios";

interface useSearchSupportContactsProps {
  supportType: "supporting" | "supports";
  searchPhrase: string;
}

export const useSearchSupportContacts = ({
  supportType,
  searchPhrase,
}: useSearchSupportContactsProps) => {
  const endpoint = `users/${supportType}?searchPhrase=${searchPhrase}`;

  async function fetchSearchedContacts(): Promise<GetContactsResponse> {
    try {
      const response: AxiosResponse<GetContactsResponse> = await apiClient.get(
        endpoint
      );
      const searchedContacts = response.data;
      return searchedContacts;
    } catch (error) {
      throw error;
    }
  }

  return useQuery(
    [`search${supportType}${searchPhrase}`, searchPhrase],
    fetchSearchedContacts,
    {
      enabled: true,
    }
  );
};
