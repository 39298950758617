import styles from "./StaticSearchBar.module.css";
import { ReactComponent as SearchIcon } from "../../components/icons/SearchIcon.svg";
import React, { SetStateAction } from "react";

interface StaticSearchBarProps {
  searchPhrase: string;
  setSearchPhrase: React.Dispatch<SetStateAction<string>>;
}

function StaticSearchBar(props: StaticSearchBarProps) {
  return (
    <div className={styles.container}>
      <input
        placeholder="Search..."
        value={props.searchPhrase}
        onChange={(e) => props.setSearchPhrase(e.target.value)}
      />
      <div>
        <SearchIcon stroke="#121212" />
      </div>
    </div>
  );
}

export default StaticSearchBar;
