import styles from "./EditablePeronalData.module.css";
import { useState, useCallback } from "react";
import { ReactComponent as PencilIcon } from "../components/icons/PencilIcon.svg";
import { ReactComponent as CloseIcon } from "../components/icons/CloseIcon.svg";
import Button from "../components/elements/Button";
import { usePatchPersonalInfo } from "../customHooks/usePatchPersonalInfo";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UserNameError } from "../customHooks/usePatchPersonalInfo";

interface InputFieldProps {
  label: string;
  value: string;
  hasError: boolean;
  errorMessage: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  maxLength: number;
}
const InputField = ({
  label,
  value,
  hasError,
  errorMessage,
  onChange,
  maxLength,
}: InputFieldProps) => (
  <div className={styles.box}>
    <p className={styles.label}>{label}</p>
    <input
      className={`${styles.input} ${hasError ? styles.input_error : ""}`}
      onChange={onChange}
      value={value}
      maxLength={maxLength}
    />
    {hasError && (
      <p className={`${styles.label} ${styles.error_message}`}>
        {errorMessage}
      </p>
    )}
  </div>
);

interface ReadOnlyFieldProps {
  label: string;
  value: string;
}
const ReadOnlyField = ({ label, value }: ReadOnlyFieldProps) => (
  <div className={styles.box}>
    <p className={styles.label}>{label}</p>
    <input className={styles.disabledInput} disabled={true} value={value} />
  </div>
);

interface EditablePeronalDataProps {
  currentName?: string;
  currentUserName?: string;
}

function EditablePersonalData({
  currentName,
  currentUserName,
}: EditablePeronalDataProps) {
  const [editMode, setEditMode] = useState<boolean>(false);
  const [name, setName] = useState<string>(currentName ? currentName : "");
  const [userName, setUserName] = useState<string>(
    currentUserName ? currentUserName : ""
  );
  const [userNameError, setUserNameError] = useState<string>("");

  const { mutate, isLoading: isMutatingPersonalInfo } = usePatchPersonalInfo();

  const handleCancel = useCallback(() => {
    setEditMode(false);
    setName(currentName ? currentName : "");
    setUserName(currentUserName ? currentUserName : "");
    setUserNameError("");
  }, [currentName, currentUserName]);

  const handleSave = useCallback(() => {
    const userNameRegex = /^[\p{L}\p{N}_]+$/u;
    setUserNameError("");
    if (userNameRegex.test(userName.trim())) {
      mutate(
        { Name: name.trim(), UserName: userName.trim() },
        {
          onError: (error) => {
            if (error instanceof UserNameError) {
              setUserNameError(error.message);
            } else {
              toast.error(
                "Something went wrong, we couldn't update your personal info",
                {
                  position: "top-center",
                  autoClose: 10000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  draggable: true,
                  theme: "colored",
                }
              );
            }
          },

          onSuccess: () => {
            setEditMode(false);
            setUserNameError("");
            setName(name.trim());
            setUserName(userName.trim());
            toast.success("Personal info updated successfully", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              draggable: true,
              theme: "light",
            });
          },
        }
      );
    } else
      setUserNameError(
        "Invalid character. Only numbers and letters are allowed."
      );
  }, [mutate, name, userName]);

  return (
    <>
      <ToastContainer />
      {editMode ? (
        <>
          <div className={styles.sectionHeader}>
            <h3 className={styles.headline}>Personal info</h3>
            <div onClick={handleCancel}>
              <CloseIcon />
            </div>
          </div>
          <InputField
            label="Name"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            hasError={name.length === 0}
            errorMessage="Name cannot be empty"
            maxLength={255}
          />
          <InputField
            label="Username"
            value={userName}
            onChange={(e) => {
              setUserName(e.target.value);
            }}
            hasError={userName.length === 0 || userNameError !== ""}
            errorMessage={userNameError || "UserName cannot be empty"}
            maxLength={26}
          />
          <div className={styles.buttonDiv}>
            <Button
              handleClick={handleSave}
              disabled={
                name.length === 0 ||
                userName.length === 0 ||
                isMutatingPersonalInfo
              }
              buttonText={isMutatingPersonalInfo ? "Loading..." : "Save"}
            />
          </div>
        </>
      ) : (
        <div>
          <div className={styles.sectionHeader}>
            <h3 className={styles.headline}>Personal info</h3>
            <div onClick={() => setEditMode(true)}>
              <PencilIcon />
            </div>
          </div>
          <ReadOnlyField label="Name" value={name} />
          <ReadOnlyField label="Username" value={userName} />
        </div>
      )}
    </>
  );
}

export default EditablePersonalData;
