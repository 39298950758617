import { AxiosResponse } from "axios";
import { apiClient } from "../../apiClient";
import { useQuery } from "react-query";

export interface GetAllCategoriesResponse {
  Name: string;
  ParentCategory: string;
  Icon?: string;
}

export const useGetAllCategories = () => {
  async function getAllCategories(): Promise<GetAllCategoriesResponse[]> {
    try {
      const response: AxiosResponse<GetAllCategoriesResponse[]> =
        await apiClient.get("categories");
      return response.data;
    } catch (error) {
      console.error("Error fetching categories:", error);
      throw error;
    }
  }
  return useQuery("getAllCategories", getAllCategories, {
    refetchOnWindowFocus: false,
  });
};
