import globalStyles from "../components/global.module.css";
import styles from "./Notifications.module.css";
import SingleNotification from "./SingleNotification";
import HeaderFeed from "../feed/HeaderFeed";
import { useNewNotificationsContext } from "./context/newNotificationContext";
import { InView } from "react-intersection-observer";
import { useReadNotification } from "./hooks/useReadNotifications";
import { useGetNotifications } from "./hooks/useGetNotifications";
import { CircularProgress } from "@mui/material";
import { useEffect, useState, Fragment } from "react";
import { NotificationType } from "./types/NotificationType";
import { SupportNotificationDataType } from "./types/SupportNotificationDataType";

function Notifications() {
  const { newNotifications, setUnreadNotificationsCount, setNewNotifications } =
    useNewNotificationsContext();
  const [allNotifications, setAllNotifications] = useState<
    NotificationType<SupportNotificationDataType>[]
  >([]);
  // append NotificationType to handle different notifications types

  const { mutate } = useReadNotification();
  const {
    data: oldNotifications,
    isLoading,
    fetchNextPage,
    isFetchingNextPage,
  } = useGetNotifications();

  useEffect(() => {
    const flattenedOldNotifications =
      oldNotifications?.pages?.flatMap((page) => page.data.notifications) ?? [];
    setAllNotifications(newNotifications.concat(flattenedOldNotifications));
  }, [oldNotifications, newNotifications]);

  const readNotification = (inView: boolean, id: string) => {
    if (inView) {
      setTimeout(() => {
        mutate(id, {
          onSuccess: () => {
            setNewNotifications((prevNotifications) =>
              prevNotifications.map((notification) =>
                notification.id === id
                  ? { ...notification, isRead: true }
                  : notification
              )
            );
          },
        });
      }, 2000);
    }
  };

  return (
    <div>
      <HeaderFeed />
      <InView
        className={styles.inView}
        onChange={(inView) => {
          if (inView) {
            setUnreadNotificationsCount(0);
          }
        }}
      />
      {isLoading ? (
        <div className={globalStyles.messageInfo}>
          <CircularProgress />
        </div>
      ) : allNotifications.length > 0 ? (
        <div className={styles.container}>
          {allNotifications.map((notification, index) =>
            notification.notificationType === 2 ? (
              <Fragment key={`${notification.id}${index}fragment`}>
                <SingleNotification
                  key={`${notification.id}${index}`}
                  senderName={notification.data.supporterName}
                  senderUserName={notification.data.supporterUserName}
                  senderAvatar={notification.data.supporterAvatar}
                  notificationInfo={`${notification.data.supporterUserName} has followed you`}
                  timestamp={notification.lastModified}
                  isRead={notification.isRead}
                />
                {notification.isRead === false && (
                  <InView
                    key={`${notification.id}${index}inview`}
                    className={styles.readInview}
                    onChange={(inView) =>
                      readNotification(inView, notification.id)
                    }
                  />
                )}
              </Fragment>
            ) : // Add here other notificaion types than 2
            null
          )}
          <InView
            className={styles.inView}
            onChange={(inView) => {
              if (inView) {
                fetchNextPage();
              }
            }}
          />
          {isFetchingNextPage && (
            <div className={styles.fetchNext}>
              <CircularProgress />
            </div>
          )}
        </div>
      ) : (
        <div className={globalStyles.messageInfo}>
          There are no new notifications
        </div>
      )}
    </div>
  );
}

export default Notifications;
