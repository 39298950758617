import { useParams } from "react-router-dom";
import GroupOverview from "./GroupOverview";
import NotFoundPage from "../../components/NotFoundPage";

function GroupOverviewWrapper() {
  const { groupId } = useParams();
  return (
    <>{groupId ? <GroupOverview groupId={groupId} /> : <NotFoundPage />}</>
  );
}

export default GroupOverviewWrapper;
