import styles from "./SelectableItem.module.css";
import Icon from "@mui/material/Icon";

interface SelectableItemProps {
  Name: string;
  Icon?: string;
  isSelected: boolean;
  handleClick: () => void;
}

function SelectableItem(props: SelectableItemProps) {
  return (
    <button
      onClick={props.handleClick}
      className={`${styles.button} ${props.isSelected && styles.selected}`}
    >
      {props.Icon && (
        <span>
          <Icon>{`${props.Icon} `}</Icon>
        </span>
      )}
      {props.Name}
    </button>
  );
}

export default SelectableItem;
