import { BasicGroupInfoType } from "../types/BasicGroupInfoType"
import { apiClient } from "../apiClient";
import { AxiosResponse } from "axios";
import { useQuery } from "react-query";

interface UseGetMyGroupsProps {
    searchPhrase?: string;
}

interface GetMyGroupsResponse {
    groups: BasicGroupInfoType[];
}

export const useGetMyGroups = ({ searchPhrase }: UseGetMyGroupsProps) => {

    async function fetchMyGroups(): Promise<GetMyGroupsResponse> {
        try {
            const response: AxiosResponse<GetMyGroupsResponse> = await apiClient.get(`groups?searchPhrase=${searchPhrase}`);
            return response.data;
        }
        catch (error) {
            throw error;
        }
    }

    return useQuery([`groups${searchPhrase}`, searchPhrase], fetchMyGroups, {
        enabled: true,
    });
}