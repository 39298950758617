import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import "./Button.css";

export default function SignInButton() {
    const { instance } = useMsal();

    function handleLogin(): void {
        instance.loginRedirect(loginRequest);
    }
    return (
        <button className="button_in_out" onClick={handleLogin}>Sign in</button>
    )
}