import HeadlineCard from "../feed/HeadlineCard";
import SummaryCard from "../feed/SummaryCard";
import TradingCard from "../feed/TradingCard";
import TwittCard from "../feed/TwittCard";
import SourceCard from "../feed/SourceCard";
import { HeadlineStoryCardApiType } from "./types/HeadlineStoryCardApiType";
import { SummaryStoryCardApiType } from "./types/SummaryStoryCardApiType";
import { StockCardApiType } from "./types/StockCardApiType";
import { TwittCardApiType } from "./types/TwittCardApiType";
import { CardTypes } from "./types/ContentResponse";
import { CardTypesEnum } from "./types/CardTypesEnum";

function cardsRenderer(
  cards: CardTypes[],
  id: string,
  dateId: string,
  source: string
) {
  const renderedCards = cards.map((card: CardTypes) => {
    switch (card?.Type) {
      case CardTypesEnum.HeadlineStoryCard:
        return (
          <HeadlineCard
            Headline={(card as HeadlineStoryCardApiType).Headline ?? ""}
            CoverUrl={(card as HeadlineStoryCardApiType).CoverUrl}
            Id={card.Id}
            DateId={dateId}
            key={`headlineCard-${id}`}
          />
        );
      case CardTypesEnum.SummaryStoryCard:
        return (
          <SummaryCard
            SummaryText={(card as SummaryStoryCardApiType).Summary ?? ""}
            Id={card.Id}
            Type={card.Type}
            key={`summaryCard-${id}`}
          />
        );
      case CardTypesEnum.StockCard:
        return (
          <TradingCard
            StockSymbol={(card as StockCardApiType).StockSymbol ?? ""}
            Id={card.Id}
            key={`tradingCard-${id}${(card as StockCardApiType).StockSymbol}`}
          />
        );
      case CardTypesEnum.TwittCard:
        return (
          <TwittCard
            TwittId={(card as TwittCardApiType).TwittId}
            Id={card.Id}
            key={`twittCard-${id}${(card as TwittCardApiType).TwittId}`}
          />
        );
      default:
        return null;
    }
  });

  return [
    ...renderedCards,
    <SourceCard
      source={source}
      coverUrl={(cards[0] as HeadlineStoryCardApiType).CoverUrl}
      key={`source-card-${dateId}`}
    />,
  ];
}

export default cardsRenderer;
