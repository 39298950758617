import { apiClient } from "../apiClient";
import { useMutation } from "react-query";

interface UseChangeSupportStatusProps {
  contactId: string;
  isSupported: boolean;
}

export const useChangeSupportStatus = () => {
  async function changeSupportStatus({
    contactId,
    isSupported,
  }: UseChangeSupportStatusProps) {
    try {
      if (isSupported) {
        await apiClient.delete(`users/supports/${contactId}`);
      } else {
        await apiClient.post(`users/supports/${contactId}`);
      }
    } catch (error) {
      throw error;
    }
  }

  return useMutation(changeSupportStatus);
};
