import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter as Router } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import { msalInstance } from "./apiClient";
import { NewNotificationsContextProvider } from "./notifications/context/newNotificationContext";
import "bootstrap/dist/css/bootstrap.min.css";
import { NewMessageEventContextProvider } from "./discussions/context/NewMessageEventContext";
import { PendingMessagesContextProvider } from "./discussions/context/PendingMessagesContext";

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <QueryClientProvider client={queryClient}>
        <Router>
          <PendingMessagesContextProvider>
            <NewMessageEventContextProvider>
              <NewNotificationsContextProvider>
                <App />
              </NewNotificationsContextProvider>
            </NewMessageEventContextProvider>
          </PendingMessagesContextProvider>
        </Router>
      </QueryClientProvider>
    </MsalProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
