import styles from "./AvatarCropperModal.module.css";
import { Modal, Slider } from "@mui/material";
import { forwardRef, useState } from "react";
import AvatarEditor from "react-avatar-editor";
import { CircularProgress } from "@mui/material";

interface AvatarCropperModalProps {
  newAvatarSrc?: string;
  modalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  croppedPicRef: React.MutableRefObject<AvatarEditor | null>;
  handleSavePicture: () => void;
  isLoading: boolean;
}
const AvatarCropperModal = forwardRef(function AvatarCropperModal({
  newAvatarSrc,
  modalOpen,
  setModalOpen,
  croppedPicRef,
  handleSavePicture,
  isLoading,
}: AvatarCropperModalProps) {
  const [slideValue, setSlideValue] = useState<number>(10);

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setSlideValue(newValue as number);
  };

  return (
    <Modal className={styles.background} open={modalOpen}>
      <div className={styles.contentBox}>
        {newAvatarSrc && (
          <>
            <AvatarEditor
              ref={croppedPicRef}
              image={newAvatarSrc}
              style={{ width: "100%", height: "100%" }}
              border={40}
              borderRadius={150}
              color={[0, 0, 0, 0.72]}
              scale={slideValue / 10}
              rotate={0}
            />

            <Slider
              min={10}
              max={50}
              sx={{
                margin: "0 auto",
                width: "80%",
                color: "#36ffb7",
              }}
              size="medium"
              defaultValue={slideValue}
              value={slideValue}
              onChange={handleSliderChange}
            />

            <div className={styles.buttonsContainer}>
              <button
                className={styles.button}
                onClick={(e) => setModalOpen(false)}
              >
                Cancel
              </button>
              <button
                className={styles.button}
                onClick={() => handleSavePicture()}
                disabled={isLoading}
              >
                {isLoading ? <CircularProgress size={14} /> : "Save"}
              </button>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
});

export default AvatarCropperModal;
