import styles from "./SupportButton.module.css";
import CircularProgress from "@mui/material/CircularProgress";
import { useChangeSupportStatus } from "../../customHooks/useChangeSupportStatus";
import { ReactComponent as ContactIcon } from "../../components/icons/ContactIcon.svg";

interface SupportButtonProps {
  isSupported: boolean;
  contactId: string;
  onSuccess?: (contactId: string, isSupported: boolean) => void;
}

function SupportButton({
  isSupported,
  contactId,
  onSuccess,
}: SupportButtonProps) {
  const { mutate, isLoading } = useChangeSupportStatus();

  const handleClick = () => {
    mutate(
      { contactId, isSupported },
      {
        onSuccess: () => {
          if (onSuccess) {
            onSuccess(contactId, isSupported);
          }
        },
      }
    );
  };

  return (
    <button
      className={`${styles.supportButton} ${
        isSupported ? styles.unfollow : styles.follow
      }`}
      onClick={handleClick}
      disabled={isLoading}
    >
      {isLoading ? (
        <CircularProgress size={14} />
      ) : isSupported ? (
        "Unfollow"
      ) : (
        <>
          <ContactIcon fill="#fff" width={20} height={20} />
          <span>Follow</span>
        </>
      )}
    </button>
  );
}

export default SupportButton;
