import styles from "./NavButton.module.css";

interface NavButtonProps {
  text: string;
  isActive: boolean;
}

function NavButton({ text, isActive }: NavButtonProps) {
  return (
    <button
      className={`${styles.button} ${
        isActive ? styles.active : styles.inactive
      }`}
    >
      {text}
    </button>
  );
}

export default NavButton;
