import { AxiosError } from "axios";
import { apiClient } from "../apiClient";
import { useMutation, useQueryClient } from "react-query";

export class UserNameError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "UserNameError";
  }
}
export const usePatchPersonalInfo = () => {
  const queryClient = useQueryClient();

  const patchPersonalInfo = async (data: {
    Name: string;
    UserName: string;
  }) => {
    const body = { Name: data.Name, UserName: data.UserName };
    try {
      await apiClient.patch("userpreferences", body);
    } catch (error) {
      if (
        (error as AxiosError).response?.status === 400 &&
        (error as AxiosError).response?.data === "UserName already exists"
      ) {
        throw new UserNameError("UserName already exists");
      }
      throw error;
    }
  };

  return useMutation(patchPersonalInfo, {
    onSuccess: () => {
      queryClient.invalidateQueries("getUserPreferences");
    },
  });
};
