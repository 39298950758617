import "./ContentCard.css";
import React, { useState, useEffect, useRef } from "react";
import SwipeableViews from "react-swipeable-views";
import { apiClient } from "../apiClient";
import ReactionsPanel from "./ReactionsPanel";
import DiscussionsOverview from "../discussions/DiscussionsOverview";
import { useParams } from "react-router-dom";
import PullToRefresh from "react-simple-pull-to-refresh";
import { useFetchInfiniteFeed } from "./hooks/useFetchInfiniteFeed";

export interface ContentCardProps {
  children: React.ReactNode[];
  cardId: string;
  dateId: string;
  favorite: boolean;
  bookmark: boolean;
  likes: number;
  pageNumber?: number;
  pullToRefetch?: boolean;
}

function ContentCard(props: ContentCardProps) {
  const [activeIndex, setActiveIndex] = useState(0);
  const activeCardRef = useRef<HTMLDivElement>(null);

  const { discussion } = useParams<{ discussion: "true" }>();

  const [cardVisible, setCardVisible] = useState<boolean | null>(null);
  const [prevCardVisible, setPrevCardVisible] = useState<boolean | null>(null);
  const [hasBeenMarkedAsRead, setHasBeenMarkedAsRead] =
    useState<boolean>(false);
  const [displayDiscussions, setDisplayDiscussions] = useState<boolean>(
    discussion === "true"
  );

  useEffect(() => {
    if (activeCardRef.current) {
      const observer = new IntersectionObserver(
        (entries) => {
          const entry = entries[0];

          if (entry.isIntersecting && entry.intersectionRatio === 1) {
            activeCardRef.current?.focus();
            setCardVisible(true);
          } else {
            setCardVisible(false);
          }
        },
        { threshold: 1 }
      );

      observer.observe(activeCardRef.current);
    }
  }, []);

  useEffect(() => {
    async function markAsRead() {
      const APIUrl = `/usertimeline/${props.dateId}/${props.cardId}/read?`;
      try {
        await apiClient.post(APIUrl);
      } catch (error) {
        throw error;
      }
      setHasBeenMarkedAsRead(true);
    }

    if (hasBeenMarkedAsRead === false) {
      if (cardVisible === false && prevCardVisible) {
        markAsRead();
      }
      setPrevCardVisible(cardVisible);
    }
  }, [
    cardVisible,
    hasBeenMarkedAsRead,
    prevCardVisible,
    props.cardId,
    props.dateId,
  ]);

  const { refetch } = useFetchInfiniteFeed();

  const handleChangeIndex = (index: number) => {
    setActiveIndex(index);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const totalIndexes = React.Children.count(props.children);
    if (event.key === "ArrowLeft" && activeIndex > 0) {
      handleChangeIndex(activeIndex - 1);
    } else if (event.key === "ArrowRight" && activeIndex < totalIndexes - 1) {
      handleChangeIndex(activeIndex + 1);
    }
  };

  const hasChildren = React.Children.count(props.children) > 0;

  return hasChildren ? (
    <>
      {displayDiscussions === false ? (
        <PullToRefresh
          isPullable={props.pullToRefetch ?? false}
          onRefresh={refetch}
          pullDownThreshold={50}
          key={`pull-to-refresh-${props.cardId}`}
        >
          <div
            ref={activeCardRef}
            key={props.cardId}
            tabIndex={0}
            onKeyDown={handleKeyPress}
            className={"swipeable_wrapper"}
          >
            <SwipeableViews
              index={activeIndex}
              onChangeIndex={handleChangeIndex}
              enableMouseEvents={true}
              resistance={true}
              className="swipeable_card"
            >
              {props.children}
            </SwipeableViews>

            <div className="dot__container">
              {props.children.map((dot, index) => (
                <div
                  className={`dot ${
                    index === activeIndex ? "dot__active" : ""
                  }`}
                  key={`${props.cardId}${index}_dot`}
                ></div>
              ))}
            </div>
            <ReactionsPanel
              likesNumber={props.likes}
              favorite={props.favorite}
              bookmark={props.bookmark}
              id={props.cardId}
              dateId={props.dateId}
              key={`reactions-panel-${props.cardId}`}
              showDiscussions={setDisplayDiscussions}
              pageNumber={props.pageNumber}
            />
          </div>
        </PullToRefresh>
      ) : (
        <DiscussionsOverview
          display={displayDiscussions}
          showDiscussionsView={setDisplayDiscussions}
          storyDateId={props.dateId}
          storyId={props.cardId}
        />
      )}
    </>
  ) : null;
}

export default ContentCard;
