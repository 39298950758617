import "./SourceCard.css";

interface SourceCardProps {
    coverUrl: string;
    source: string;
}

function SourceCard(props: SourceCardProps) {
    return (
        <>
            <img src={props.coverUrl} alt="cover" className="sourceCardImg" />
            <div className="sourceContainer">
                <span>
                    <a href={props.source} target="_blank" rel="noopener noreferrer">Visit the full article</a>
                </span>
            </div>

        </>
    );
}
export default SourceCard