import { useMutation, useQueryClient } from "react-query";
import { apiClient } from "../apiClient";
import { DiscussionType } from "../types/DiscussionType";
import { GetDiscussionResponse, MessageResponseType } from "./useGetDiscussion";

interface UsePostCommentReactionProps {
    storyDateId: string;
    storyId: string;
    messagesHeaderId: string;
    discussionType: DiscussionType;
    discussionRecipientId?: string;
    messageId: string,
    chosenReaction: string,
    newReaction: string | null,
    newReactionCount: number,
}

export const useSendCommentReaction = (
    { storyDateId, storyId, messagesHeaderId, discussionType, discussionRecipientId, messageId, chosenReaction, newReaction, newReactionCount }: UsePostCommentReactionProps) => {

    const queryClient = useQueryClient();

    const endpoints: Record<DiscussionType, string> = {
        World: `discussions/${storyDateId}/${storyId}/messages/${messagesHeaderId}/reactions`,
        User: `discussions/${storyDateId}/${storyId}/users/${discussionRecipientId}/messages/${messagesHeaderId}/reactions`,
        Group: `discussions/${storyDateId}/${storyId}/groups/${discussionRecipientId}/messages/${messagesHeaderId}/reactions`,
    };

    const endpoint = endpoints[discussionType] || "";

    async function sendCommentReaction(currentReaction: null | string) {
        try {
            if (currentReaction === null) {
                await apiClient.post(endpoint, {
                    "Reaction": {
                        "Value": chosenReaction,
                    }
                });
            } else {
                await apiClient.delete(endpoint);
            }
        } catch (error) {
            throw error;
        }
    }

    return useMutation(sendCommentReaction, {
        onMutate: async () => {

            await queryClient.cancelQueries(`getDiscussions${discussionType}${storyId}${discussionRecipientId ? discussionRecipientId : 'World'}`);

            const discussionQueryData: GetDiscussionResponse | undefined = queryClient.getQueryData(
                `getDiscussions${discussionType}${storyId}${discussionRecipientId ? discussionRecipientId : 'World'}`
            );

            if (discussionQueryData) {
                const messageToUpdate = discussionQueryData.discussion.displayMessages.find(message => message.id === messageId);

                if (messageToUpdate) {

                    const previousMessage: MessageResponseType = { ...messageToUpdate }

                    queryClient.setQueryData<GetDiscussionResponse | undefined>(
                        `getDiscussions${discussionType}${storyId}${discussionRecipientId ? discussionRecipientId : 'World'}`,
                        data => {
                            if (data) {
                                return {
                                    ...data,
                                    discussion: {
                                        ...data.discussion,
                                        displayMessages: data.discussion.displayMessages.map(message => {
                                            if (message.id === messageId) {
                                                return {
                                                    ...messageToUpdate,
                                                    currentUserReaction: newReaction,
                                                    reactionsCount: {
                                                        ...messageToUpdate.reactionsCount,
                                                        [chosenReaction]: newReactionCount,
                                                    }
                                                };
                                            }
                                            return message;
                                        })
                                    }
                                } as GetDiscussionResponse;
                            }
                            return undefined;
                        }
                    );
                    return { previousData: previousMessage };
                }
            }
        },

        onError: (error, variables, context) => {

            if (context?.previousData) {
                queryClient.setQueryData<GetDiscussionResponse | undefined>(
                    `getDiscussions${discussionType}${storyId}${discussionRecipientId ? discussionRecipientId : 'World'}`,
                    (oldData) => {
                        if (oldData) {
                            return {
                                ...oldData,
                                discussion: {
                                    ...oldData.discussion,
                                    displayMessages: oldData.discussion.displayMessages.map(message => {
                                        if (message.id === messageId) {
                                            return {
                                                ...context.previousData,
                                            };
                                        }
                                        return message;
                                    })
                                }
                            } as GetDiscussionResponse;
                        }
                        return undefined;
                    }
                );
            }
        }
    });
};
