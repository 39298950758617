import { useMutation } from "react-query";
import { apiClient } from "../apiClient";
import { useNavigate } from "react-router-dom";
import { AxiosResponse } from "axios";

interface PostNewGroupResponse {
  membersNotFound: null | string[];
  group: {
    id: string;
    name: string;
    groupOwner: {
      id: string;
    };
    groupMembers: [
      {
        id: string;
      }
    ];
  };
}

export const usePostNewGroup = () => {
  const navigate = useNavigate();

  async function postGroup(data: {
    groupName: string;
    groupMembers: string[];
  }): Promise<PostNewGroupResponse> {
    const body = {
      Name: data.groupName,
      GroupMembers: data.groupMembers,
    };
    try {
      const response: AxiosResponse<PostNewGroupResponse> =
        await apiClient.post("groups", body);
      return response.data;
    } catch (error) {
      throw new Error("Error posting a group");
    }
  }
  return useMutation(postGroup, {
    onSuccess: (postGroupData: PostNewGroupResponse) =>
      navigate(`/Community/Groups/${postGroupData.group.id}`),
  });
};
