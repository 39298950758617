import { apiClient } from "../apiClient";
import { useQuery } from "react-query";
import { DiscussionType } from "../types/DiscussionType";
import { AxiosResponse } from "axios";
import { MessageBasicType } from "../discussions/types/MessageBasicType";

export interface useGetDiscussionProps {
  discussionType: DiscussionType;
  storyId: string;
  storyDateId: string;
  recipientId?: string;
}

export interface MessageResponseType extends MessageBasicType {
  isDeleted: boolean;
  deletedAt: null | string;
  reactionsCount: { [key: string]: number };
  currentUserReaction: null | string;
  isCurrentUserMessage: boolean;
  isRead: boolean;
}

export interface GetDiscussionResponse {
  discussion: {
    story: { id: string; dateId: string };
    displayMessages: MessageResponseType[];
  };
  numberOfMessages: number;
  numberOfUnread: number;
  lastReadTimestamp: string;
}

export const useGetDiscussion = ({
  discussionType,
  storyId,
  storyDateId,
  recipientId,
}: useGetDiscussionProps) => {
  const endpoints: Record<DiscussionType, string> = {
    World: `discussions/${storyDateId}/${storyId}`,
    User: `discussions/${storyDateId}/${storyId}/users/${recipientId}`,
    Group: `discussions/${storyDateId}/${storyId}/groups/${recipientId}`,
  };

  const endpoint = endpoints[discussionType] || "";

  {
    const fetchDiscussion = async (): Promise<GetDiscussionResponse> => {
      try {
        const response: AxiosResponse<GetDiscussionResponse> =
          await apiClient.get(endpoint);
        return response.data;
      } catch (error) {
        throw error;
      }
    };

    const queryResult = useQuery(
      `getDiscussions${discussionType}${storyId}${
        recipientId ? recipientId : "World"
      }`,
      fetchDiscussion,
      {
        refetchOnWindowFocus: false,
      }
    );

    const latestMessageTimestamp =
      queryResult.data && queryResult.data.discussion.displayMessages.length > 0
        ? queryResult.data.discussion.displayMessages[0].timestamp
        : null;

    return {
      ...queryResult,
      latestMessageTimestamp,
    };
  }
};
