import styles from "./NotFoundPage.module.css";
import { Link } from "react-router-dom";

function NotFoundPage() {
  return (
    <div className={styles.div}>
      <div className={styles.content}>
        <p>We seem not to find the page you are looking for</p>
        <p className={styles.error}>404</p>
        <Link to="/">
          <button className={styles.button}>Return to Home Page</button>
        </Link>
      </div>
    </div>
  );
}

export default NotFoundPage;
