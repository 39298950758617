import { QueryClient } from "react-query";
import { ContentResponse } from "../../types/ContentResponse";
import { FilterStoriesType } from "../../types/FilterStoriesType";

interface UpdateSingleStoryCacheProps {
  queryClient: QueryClient;
  storyId: string;
  updatedPropertyName: FilterStoriesType;
  newValue: boolean;
}

export const updateSingleStoryCache = ({
  queryClient,
  storyId,
  updatedPropertyName,
  newValue,
}: UpdateSingleStoryCacheProps): ContentResponse | null => {
  const singleStoryQueryData: ContentResponse | undefined =
    queryClient.getQueryData(`fetchStory${storyId}`);

  if (singleStoryQueryData) {
    const prevSingleStoryData = { ...singleStoryQueryData };

    queryClient.cancelQueries(`fetchStory${storyId}`);

    queryClient.setQueryData<ContentResponse | undefined>(
      `fetchStory${storyId}`,
      (data) => {
        if (data) {
          if (updatedPropertyName === "Favorite") {
            return {
              ...data,
              Favorite: newValue,
              Likes: newValue ? data.Likes + 1 : data.Likes - 1,
            };
          } else
            return {
              ...data,
              Bookmark: newValue,
            };
        }
        return undefined;
      }
    );
    return prevSingleStoryData;
  }
  return null;
};
