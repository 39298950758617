import {
  createContext,
  useContext,
  useState,
  SetStateAction,
  ReactNode,
} from "react";

interface PendingMessagesContextType {
  pendingMessages: boolean;
  setPendingMessages: React.Dispatch<SetStateAction<boolean>>;
}

interface PendingMessagesContextProviderProps {
  children: ReactNode;
}

const PendingMessagesContext = createContext<PendingMessagesContextType | null>(
  null
);

export const PendingMessagesContextProvider = ({
  children,
}: PendingMessagesContextProviderProps) => {
  const [pendingMessages, setPendingMessages] = useState<boolean>(false);

  return (
    <PendingMessagesContext.Provider
      value={{
        pendingMessages: pendingMessages,
        setPendingMessages: setPendingMessages,
      }}
    >
      {children}
    </PendingMessagesContext.Provider>
  );
};

export function usePendingMessagesContext() {
  const context = useContext(PendingMessagesContext);
  if (!context) {
    throw new Error(
      "usePendingMessagesContext must be used within a PendingMessagesContextProvider"
    );
  }
  return context;
}
