import styles from "./AddContactsModal.module.css";
import globalStyles from "../../components/global.module.css";
import { ReactComponent as ArrowBackIcon } from "../../components/icons/ArrowBackIcon.svg";
import StaticSearchBar from "../../components/elements/StaticSearchBar";
import Button from "../../components/elements/Button";
import CircularProgress from "@mui/material/CircularProgress";
import SelectableContactsList from "./SelectableContactsList";
import { useState } from "react";
import { useSearchSupportContacts } from "../../customHooks/useSearchSupportContacts";
import { useDebounce } from "react-use";
import { MemberBasicInfoType } from "../../types/MemberBasicInfoType";

interface AddContactsModalProps {
  onArrowBackClick: () => void;
  currentMembers: MemberBasicInfoType[];
  handleAddClick: (members: string[]) => void;
  addButtonText: string;
  addButtonDisabled?: boolean;
  display: boolean;
}

function AddContactsModal(props: AddContactsModalProps) {
  const [selectedContacts, setSelectedContacts] = useState<string[]>([]);
  const [searchPhrase, setSearchPhrase] = useState<string>("");
  const [debouncedSearchPhrase, setDebouncedSearchPhrase] =
    useState<string>("");

  useDebounce(() => setDebouncedSearchPhrase(searchPhrase), 500, [
    searchPhrase,
  ]);

  const {
    data: contacts,
    isLoading: isLoadingContacts,
    isError: isErrorLoadingContacts,
  } = useSearchSupportContacts({
    supportType: "supporting",
    searchPhrase: debouncedSearchPhrase,
  });

  return props.display ? (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.arrowBack} onClick={props.onArrowBackClick}>
          <ArrowBackIcon />
        </div>
        <StaticSearchBar
          searchPhrase={searchPhrase}
          setSearchPhrase={setSearchPhrase}
        />
      </div>
      <div className={styles.contactsList}>
        {isLoadingContacts ? (
          <div className={globalStyles.messageInfo}>
            <CircularProgress />
          </div>
        ) : isErrorLoadingContacts ? (
          <div className={globalStyles.messageInfo}>
            <p>Something went wrong.</p>
          </div>
        ) : contacts && contacts.Supporting ? (
          <SelectableContactsList
            foundContacts={contacts.Supporting}
            selectedContacts={selectedContacts}
            setSelectedContacts={setSelectedContacts}
            currentMembers={props.currentMembers}
          />
        ) : null}
      </div>
      <div className={styles.footer}>
        <Button
          handleClick={() => {
            props.handleAddClick(selectedContacts);
          }}
          disabled={props.addButtonDisabled || selectedContacts.length === 0}
          buttonText={props.addButtonText}
        />
      </div>
    </div>
  ) : null;
}

export default AddContactsModal;
