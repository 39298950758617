import React from "react";
import { useParams } from "react-router-dom";
import ContactsList from "./ContactsList";
import NotFoundPage from "../../components/NotFoundPage";

function ContactsListWrapper() {
  const { followType } = useParams<{ followType: "followers" | "following" }>();

  return (
    <div>
      {followType?.toLowerCase() === "followers" ||
      followType?.toLowerCase() === "following" ? (
        <ContactsList followType={followType} />
      ) : (
        <NotFoundPage />
      )}
    </div>
  );
}

export default ContactsListWrapper;
