import { AxiosResponse } from "axios";
import { apiClient } from "../../apiClient";
import { useQuery } from "react-query";

interface GetAllLanguagesResponse {
  Language: string;
  LanguageCode: string;
  Icon?: string;
}

export const useGetAllLanguages = () => {
  async function getAllLanguages(): Promise<GetAllLanguagesResponse[]> {
    try {
      const response: AxiosResponse<GetAllLanguagesResponse[]> =
        await apiClient.get("languages");
      return response.data;
    } catch (error) {
      console.error("Error fetching languages:", error);
      throw error;
    }
  }
  return useQuery("getAllLanguages", getAllLanguages, {
    refetchOnWindowFocus: false,
  });
};
