import "./SingleDiscussion.css";
import Comment from "./reusableComponents/Comment";
import {
  GetDiscussionResponse,
  MessageResponseType,
  useGetDiscussion,
  useGetDiscussionProps,
} from "../customHooks/useGetDiscussion";
import CircularProgress from "@mui/material/CircularProgress";
import globalStyles from "../components/global.module.css";
import { useNewMessageEventContext } from "./context/NewMessageEventContext";
import { useCallback, useEffect } from "react";
import { NewMessageNotificationType } from "./types/NewMessageNotificationType";
import { useMemo } from "react";
import { useQueryClient, InfiniteData, QueryClient } from "react-query";
import { AxiosResponse } from "axios";
import { useDeleteNewMessagesNotifications } from "./hooks/useDeleteNewMessageNotifications";
import { RecipientType } from "./types/RecipientType";
import { useReadMessage } from "./hooks/useReadMessage";
import { GetActivitiesResponse } from "../customHooks/useGetDiscussionsActivities";
import { GetPostActivitiesResponse } from "../customHooks/useGetPostActivities";
import { DiscussionType } from "../types/DiscussionType";

interface SingleDiscussionProps extends useGetDiscussionProps {}

function SingleDiscussion({
  storyId,
  storyDateId,
  discussionType,
  recipientId,
}: SingleDiscussionProps) {
  const {
    data: discussionData,
    latestMessageTimestamp,
    isLoading,
    isError,
  } = useGetDiscussion({
    discussionType: discussionType,
    storyId: storyId,
    storyDateId: storyDateId,
    recipientId: recipientId,
  });

  const { mutate: deleteNotifications } = useDeleteNewMessagesNotifications();
  const { mutate: postReadMessage } = useReadMessage({
    storyDateId: storyDateId,
    storyId: storyId,
    recipientType:
      discussionType === "Group"
        ? RecipientType.Group
        : discussionType === "User"
        ? RecipientType.User
        : null,
    recipientId: recipientId ?? null,
  });

  const lastMessageDate = useMemo(() => {
    if (latestMessageTimestamp) {
      return new Date(latestMessageTimestamp);
    }
    return null;
  }, [latestMessageTimestamp]);

  const { subscribe, unsubscribe } = useNewMessageEventContext();

  const queryClient = useQueryClient();

  const isValidDiscussionType = (type: string): type is RecipientType => {
    return type === RecipientType.Group || type === RecipientType.User;
  };

  const updateDirectActivitiesCacheWithReadState = (
    storyId: string,
    storyDateId: string,
    recipientId: string,
    recipientType: RecipientType,
    queryClient: QueryClient
  ) => {
    queryClient.setQueryData(
      "getDirectActivities",
      (
        oldData: InfiniteData<AxiosResponse<GetActivitiesResponse>> | undefined
      ): InfiniteData<AxiosResponse<GetActivitiesResponse>> => {
        if (!oldData) {
          return { pages: [], pageParams: [] };
        }

        return {
          ...oldData,
          pages: oldData.pages?.map((page) => ({
            ...page,
            data: {
              ...page.data,
              activities: page.data.activities?.map((activity) => {
                if (
                  activity.story.id === storyId &&
                  activity.story.dateId === storyDateId
                ) {
                  return {
                    ...activity,
                    recipients: activity.recipients.map((recipient) =>
                      recipient.id === recipientId &&
                      recipient.recipientType === recipientType
                        ? { ...recipient, unreadMessagesCount: 0 }
                        : recipient
                    ),
                  };
                }
                return activity;
              }),
            },
          })),
        };
      }
    );
  };

  const updateStoryActivitiesCacheWithReadState = (
    storyId: string,
    recipientId: string,
    recipientType: RecipientType,
    queryClient: QueryClient
  ) => {
    queryClient.cancelQueries(`getPostActivities${storyId}`);

    queryClient.setQueryData<GetPostActivitiesResponse | undefined>(
      `getPostActivities${storyId}`,
      (oldData: GetPostActivitiesResponse | undefined) => {
        if (oldData) {
          return {
            ...oldData,
            activity: {
              ...oldData.activity,
              activeRecipients: oldData.activity.activeRecipients.map(
                (recipient) =>
                  recipient.id === recipientId &&
                  recipient.recipientType === recipientType
                    ? { ...recipient, unreadMessagesCount: 0 }
                    : recipient
              ),
            },
          };
        }
      }
    );
  };

  const updateParticularDiscussionWithNewMessage = (
    storyId: string,
    recipientId: string | undefined,
    discussionType: DiscussionType,
    message: NewMessageNotificationType,
    queryClient: QueryClient
  ) => {
    queryClient.setQueryData<GetDiscussionResponse | undefined>(
      `getDiscussions${discussionType}${storyId}${recipientId}`,
      (prevData: GetDiscussionResponse | undefined) => {
        if (prevData) {
          return {
            ...prevData,
            discussion: {
              ...prevData.discussion,
              displayMessages: [
                {
                  id: message.id,
                  content: message.content,
                  senderAvatar: message.senderAvatar,
                  senderUserName: message.senderUserName,
                  timestamp: message.timestamp,
                  senderId: message.senderId,
                  senderName: message.senderName,
                  contentType: message.contentType,
                  messageType: message.messageType,
                  isDeleted: false,
                  deletedAt: null,
                  reactionsCount: { "\u2764": 0 },
                  currentUserReaction: null,
                  isCurrentUserMessage: false,
                  isRead: true,
                },
                ...prevData.discussion.displayMessages,
              ],
            },
          };
        }
        return prevData;
      }
    );
  };

  const handleNewMessage = useCallback(
    (message: NewMessageNotificationType) => {
      const newMessageTime = new Date(message.timestamp);

      if (!lastMessageDate || newMessageTime > lastMessageDate) {
        updateParticularDiscussionWithNewMessage(
          storyId,
          recipientId,
          discussionType,
          message,
          queryClient
        );
      }
    },
    [discussionType, lastMessageDate, queryClient, recipientId, storyId]
  );

  const handleReadMessages = useCallback(() => {
    postReadMessage();

    const directActivitiesQueryData = queryClient.getQueryData(
      "getDirectActivities"
    );
    const storyActivitiesQueryData = queryClient.getQueryData(
      `getPostActivities${storyId}`
    );

    if (!recipientId || !isValidDiscussionType(discussionType)) {
      return;
    }

    if (directActivitiesQueryData) {
      updateDirectActivitiesCacheWithReadState(
        storyId,
        storyDateId,
        recipientId,
        discussionType as RecipientType,
        queryClient
      );
    }

    if (storyActivitiesQueryData) {
      updateStoryActivitiesCacheWithReadState(
        storyId,
        recipientId,
        discussionType as RecipientType,
        queryClient
      );
    }
  }, [
    discussionType,
    postReadMessage,
    queryClient,
    storyDateId,
    storyId,
    recipientId,
  ]);

  useEffect(() => {
    if (isValidDiscussionType(discussionType) && recipientId) {
      const filter = {
        callerType: discussionType as RecipientType,
        callerId: recipientId,
        storyId: storyId,
        storyDateId: storyDateId,
      };

      subscribe(filter, handleNewMessage);

      return () => {
        unsubscribe(handleNewMessage);
        deleteNotifications({
          storyDateId: storyDateId,
          storyId: storyId,
          callerType: discussionType as RecipientType,
          callerId: recipientId,
        });
      };
    }
  }, [
    discussionType,
    recipientId,
    storyId,
    storyDateId,
    subscribe,
    unsubscribe,
    handleNewMessage,
    deleteNotifications,
  ]);

  useEffect(() => {
    if (
      (discussionType === RecipientType.User ||
        discussionType === RecipientType.Group) &&
      recipientId
    ) {
      handleReadMessages();
    }

    return () => {
      handleReadMessages();
    };
  }, [discussionType, recipientId, handleReadMessages]);

  useEffect(() => {
    if (
      (discussionType === RecipientType.User ||
        discussionType === RecipientType.Group) &&
      recipientId
    ) {
      deleteNotifications({
        storyDateId: storyDateId,
        storyId: storyId,
        callerType: discussionType as RecipientType,
        callerId: recipientId,
      });
    }
  }, [storyDateId, storyId, recipientId, discussionType, deleteNotifications]);

  if (isLoading) {
    return (
      <div className={globalStyles.centralMessage}>
        <CircularProgress />
      </div>
    );
  }

  if (isError) {
    return (
      <div className={globalStyles.messageInfo}>
        <p>Something went wrong, please refresh the page.</p>
      </div>
    );
  }

  if (discussionData?.discussion.displayMessages) {
    return (
      <>
        <div className="singleDiscussion__flexGrow"></div>
        <div className="singleDiscussion__discussions">
          {discussionData.discussion.displayMessages.length === 0 ? (
            <div className={globalStyles.messageInfo}>
              <p>There are no comments yet</p>
            </div>
          ) : (
            discussionData.discussion.displayMessages.map(
              (message: MessageResponseType) => (
                <Comment
                  id={message.id}
                  content={message.content}
                  senderAvatar={message.senderAvatar}
                  senderName={message.senderName}
                  senderUserName={message.senderUserName}
                  timestamp={message.timestamp}
                  isDeleted={message.isDeleted}
                  reactionsCount={message.reactionsCount}
                  currentUserReaction={message.currentUserReaction}
                  isCurrentUserMessage={message.isCurrentUserMessage}
                  discussionType={discussionType}
                  discussionRecipientId={recipientId}
                  storyDateId={storyDateId}
                  storyId={storyId}
                  key={message.id}
                />
              )
            )
          )}
        </div>
      </>
    );
  }
  return null;
}

export default SingleDiscussion;
