import { QueryFunctionContext, QueryKey, useInfiniteQuery } from "react-query";
import { ContentResponse } from "../types/ContentResponse";
import { AxiosResponse } from "axios";
import { FeedResponseData } from "./useFetchInfiniteFeed";
import { apiClient } from "../../apiClient";

interface UseFilterTimelineProps {
  type: "Bookmark" | "Favorite";
  numberOfStories: number;
}

export interface FilterTimelineApiResponse {
  LastReadNewsId: string;
  DateId: string;
  Stories: ContentResponse[];
}

interface PageParam {
  LastReadNewsId?: string;
  DateId?: string;
}

interface TimelineFilters {
  DateId?: string | null;
  LastNewsId?: string | null;
  NumberOfNews: number;
  Bookmark?: boolean;
  Favorite?: boolean | null;
}

export const useFilterTimeline = ({
  type,
  numberOfStories,
}: UseFilterTimelineProps) => {
  async function postTimelineSearch(
    context: QueryFunctionContext<QueryKey, PageParam>
  ): Promise<FilterTimelineApiResponse | undefined> {
    let { pageParam } = context;
    let requestNumber = 0;

    while (requestNumber < 9) {
      try {
        const body: TimelineFilters = {
          DateId: pageParam?.DateId ?? null,
          LastNewsId: pageParam?.LastReadNewsId ?? null,
          NumberOfNews: numberOfStories,
          Bookmark: type === "Bookmark" ? true : undefined,
          Favorite: type === "Favorite" ? true : undefined,
        };
        const response: AxiosResponse<FeedResponseData> = await apiClient.post(
          "timeline/search",
          body
        );

        if (response.data.Stories?.length > 0) {
          return {
            Stories: response.data.Stories,
            DateId: response.data.DateId,
            LastReadNewsId: response.data.LastReadNewsId,
          };
        } else {
          requestNumber += 1;
          pageParam = {
            LastReadNewsId: response.data.LastReadNewsId,
            DateId: response.data.DateId,
          };
        }
      } catch (error) {
        console.error("Error filtering timeline: ", error);
        throw error;
      }
    }
  }

  return useInfiniteQuery(`filter${type}`, postTimelineSearch, {
    getNextPageParam: (lastPage) => {
      if (lastPage && lastPage.DateId) {
        return {
          LastReadNewsId: lastPage.LastReadNewsId,
          DateId: lastPage.DateId,
        };
      } else return undefined;
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
};
