import { useParams } from "react-router-dom";
import FilterStories from "./FilterStories";
import NotFoundPage from "../components/NotFoundPage";

function FilterStoriesWrapper() {
  const { filterType } = useParams();

  return (
    <>
      {filterType?.toLowerCase() === "bookmarks" ? (
        <FilterStories filterType="Bookmark" />
      ) : filterType?.toLowerCase() === "favorites" ? (
        <FilterStories filterType="Favorite" />
      ) : (
        <NotFoundPage />
      )}
    </>
  );
}

export default FilterStoriesWrapper;
