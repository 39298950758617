import styles from "./UserPreferences.module.css";
import globalStyles from "../components/global.module.css";
import CircularProgress from "@mui/material/CircularProgress";
import {
  GetAllCategoriesResponse,
  useGetAllCategories,
} from "./hooks/useGetAllCategories";
import SelectableItem from "./SelectableItem";

interface GroupedCategory {
  [parentCategory: string]: GetAllCategoriesResponse[];
}

interface CategoriesSelectorProps {
  preferredCategories: string[];
  setPreferredCategories: React.Dispatch<React.SetStateAction<string[]>>;
  handleSubmitButton: () => void;
  isUpdatingPreferences: boolean;
}

function groupByParentCategory(data: GetAllCategoriesResponse[]) {
  return data.reduce(
    (acc: { [key: string]: GetAllCategoriesResponse[] }, item) => {
      const { ParentCategory, ...rest } = item;
      if (!acc[ParentCategory]) {
        acc[ParentCategory] = [];
      }
      acc[ParentCategory].push({ ...rest, ParentCategory });
      return acc;
    },
    {}
  );
}

function CategoriesSelector(props: CategoriesSelectorProps) {
  const { data: allCategories, isLoading, isError } = useGetAllCategories();

  const groupedCategories: GroupedCategory = groupByParentCategory(
    allCategories || []
  );

  const toggleCategorySelection = (category: string) => {
    props.setPreferredCategories((prevPreferredCategories) => {
      const isSelected = prevPreferredCategories.includes(category);
      return isSelected
        ? prevPreferredCategories.filter(
            (selectedCategory) => selectedCategory !== category
          )
        : [...prevPreferredCategories, category];
    });
  };

  const toggleClusterSelection = (
    subCategories: GetAllCategoriesResponse[]
  ) => {
    const areAllSelected = subCategories.every((subcategory) =>
      props.preferredCategories.includes(subcategory.Name)
    );

    props.setPreferredCategories((prevPreferredCategories) =>
      areAllSelected
        ? prevPreferredCategories.filter(
            (selectedCategory) =>
              !subCategories.some(
                (subcategory) => selectedCategory === subcategory.Name
              )
          )
        : [
            ...new Set([
              ...prevPreferredCategories,
              ...subCategories.map((subcategory) => subcategory.Name),
            ]),
          ]
    );
  };
  if (isLoading) {
    return (
      <div className={globalStyles.messageInfo}>
        <CircularProgress />
      </div>
    );
  }

  if (isError) {
    return (
      <div className={globalStyles.messageInfo}>
        Something went wrong, please try again later.
      </div>
    );
  }

  if (!allCategories) {
    return null;
  }

  return (
    <>
      <h3 className={styles.sectionHeader}>
        Dive Into Your Favorite Categories!
      </h3>
      <h4 className={styles.sectionDescription}>
        Select <span>at least five</span> categories that resonate with your
        passions, and unlock a world of content personalized just for you.
      </h4>
      <div>
        {Object.entries(groupedCategories).map(
          ([parent, subCategories], parentIndex) => (
            <div className={styles.parentCluster} key={parentIndex}>
              <h4
                className={
                  subCategories.every((subcategory) =>
                    props.preferredCategories.includes(subcategory.Name)
                  )
                    ? styles.clusterSelected
                    : ""
                }
                onClick={() => toggleClusterSelection(subCategories)}
                key={`${parent}${parentIndex}`}
              >
                {parent.toUpperCase()}
              </h4>
              <div
                className={styles.subcategoriesOptionsContainer}
                key={`${parent}container`}
              >
                {subCategories.map((subCategory) => (
                  <div
                    className={styles.itemContainer}
                    key={`${subCategory.Name}`}
                  >
                    <SelectableItem
                      Name={subCategory.Name}
                      Icon={subCategory.Icon}
                      handleClick={() =>
                        toggleCategorySelection(subCategory.Name)
                      }
                      isSelected={props.preferredCategories.includes(
                        subCategory.Name
                      )}
                      key={`${subCategory.ParentCategory}${subCategory.Name}`}
                    />
                  </div>
                ))}
              </div>
            </div>
          )
        )}
      </div>
      <div className={styles.buttonContainer}>
        <button
          className={styles.submitButton}
          disabled={props.preferredCategories.length < 5}
          onClick={() => props.handleSubmitButton()}
        >
          {props.isUpdatingPreferences ? (
            <CircularProgress size={14} />
          ) : (
            "Submit"
          )}
        </button>
      </div>
    </>
  );
}

export default CategoriesSelector;
