import "./ReactionsPanel.css";
import { SetStateAction, useState } from "react";
import { ReactComponent as HeartIcon } from "../components/icons/HeartIcon.svg";
import { ReactComponent as HeartIconLiked } from "../components/icons/HeartIconLiked.svg";
import { ReactComponent as DiscussionsIcon } from "../components/icons/CommentIcon.svg";
import { ReactComponent as ShareIcon } from "../components/icons/ShareIcon.svg";
import { ReactComponent as BookmarkIcon } from "../components/icons/BookmarkIcon.svg";
import { ReactComponent as BookmarkIconFilled } from "../components/icons/BookmarkIconFilled.svg";
import ShareStoryPopUp from "./ShareStoryPopUp";
import { useSendStoryReaction } from "./hooks/useSendStoryReaction";
import { useToggleBookmark } from "./hooks/useToggleBookmark";

interface ReactionsPanelProps {
  likesNumber: number;
  favorite: boolean;
  bookmark: boolean;
  id: string;
  dateId: string;
  pageNumber?: number;
  showDiscussions: React.Dispatch<SetStateAction<boolean>>;
}

function ReactionsPanel(props: ReactionsPanelProps) {
  const [shareStoryView, showShareStoryView] = useState<boolean>(false);

  const { mutate } = useSendStoryReaction({
    storyId: props.id,
    storyDateId: props.dateId,
    isFavorite: props.favorite,
    pageNumber: props.pageNumber,
  });

  const { mutate: toggleBookmark } = useToggleBookmark({
    storyId: props.id,
    storyDateId: props.dateId,
    pageNumber: props.pageNumber,
    isBookmarked: props.bookmark,
  });

  return (
    <>
      <div className="reactionsPanel">
        <div className="reactionsPanel__countable" onClick={() => mutate()}>
          {props.favorite ? <HeartIconLiked /> : <HeartIcon />}
          <span className="reactionsPanel_number">{props.likesNumber}</span>
        </div>

        <div
          className="reactionsPanel__countable"
          onClick={() => props.showDiscussions(true)}
        >
          <DiscussionsIcon />
          {/* <span className='reactionsPanel_number'>122</span> */}
        </div>

        <div
          className="reactionsPanel__nonCountable"
          onClick={() => showShareStoryView(true)}
        >
          <ShareIcon />
        </div>

        <div
          className="reactionsPanel__nonCountable"
          onClick={() => toggleBookmark()}
        >
          {props.bookmark ? <BookmarkIconFilled /> : <BookmarkIcon />}
        </div>
      </div>
      <ShareStoryPopUp
        trigger={shareStoryView}
        showPopUp={showShareStoryView}
        newsDate={props.dateId}
        newsId={props.id}
      />
    </>
  );
}

export default ReactionsPanel;
