import { apiClient } from "../apiClient";
import { useQuery } from "react-query";
import { UserContactResponseType } from "../types/UserContactResponseType";
import { AxiosResponse } from "axios";

interface useSearchWithinAllUsersProps {
  searchPhrase: string;
}

export const useSearchWithinAllUsers = ({
  searchPhrase,
}: useSearchWithinAllUsersProps) => {
  async function fetchUsers(): Promise<{
    MatchedUsers: UserContactResponseType[];
  }> {
    try {
      const response: AxiosResponse<{
        MatchedUsers: UserContactResponseType[];
      }> = await apiClient.get(`users/search?searchPhrase=${searchPhrase}`);
      return response.data;
    } catch (error) {
      throw new Error("Error fetching users");
    }
  }
  return useQuery(["fetchUsers", searchPhrase], fetchUsers, {
    refetchOnMount: false,
    enabled: searchPhrase.length > 0,
  });
};
