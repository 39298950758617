import './SummaryCard.css'

export interface SummaryCardProps {
    SummaryText: string;
    Id: number;
    Type: string;
}

function SummaryCard(props: SummaryCardProps) {

    const paragraphs = props.SummaryText.split('\n\n');

    return (
        <div className='summaryCard' key={props.Id}>
            <div className='summaryCard__TextBackground'>
                {paragraphs.map((paragraph, index) => (
                    <p key={index}>{paragraph}</p>
                ))}
            </div>
        </div>

    )
}

export default SummaryCard;