import { useEffect, useCallback } from "react";
import { usePendingMessagesContext } from "../discussions/context/PendingMessagesContext";
import {
  ActivityResponse,
  GetActivitiesResponse,
  RecipientsData,
  useGetDiscussionsActivities,
} from "./useGetDiscussionsActivities";
import { useNewMessageEventContext } from "../discussions/context/NewMessageEventContext";
import { useQueryClient, QueryClient, InfiniteData } from "react-query";
import { NewMessageNotificationType } from "../discussions/types/NewMessageNotificationType";
import { AxiosResponse } from "axios";
import { RecipientType } from "../discussions/types/RecipientType";

const useHandleNewPrivateDiscussionActivity = () => {
  const { setPendingMessages } = usePendingMessagesContext();
  const { data: privateDiscussionActivities } = useGetDiscussionsActivities({
    discussionType: "private",
  });

  useEffect(() => {
    const pages = privateDiscussionActivities?.pages ?? [];
    const firstPageData = pages[0]?.data ?? null;

    if (!firstPageData) {
      return;
    }

    const hasPendingMessages = firstPageData.activities.some((story) =>
      story.recipients.some((recipient) => recipient.unreadMessagesCount > 0)
    );

    setPendingMessages(hasPendingMessages);
  }, [privateDiscussionActivities, setPendingMessages]);

  const { subscribe, unsubscribe } = useNewMessageEventContext();
  const queryClient = useQueryClient();

  const updateDirectActivitiesCacheWithNewActivity = (
    queryClient: QueryClient,
    message: NewMessageNotificationType
  ) => {
    const directActivitiesQueryData:
      | InfiniteData<AxiosResponse<GetActivitiesResponse>>
      | undefined = queryClient.getQueryData("getDirectActivities");

    const updateActivityPages = (
      page: AxiosResponse<GetActivitiesResponse>,
      pageIndex: number,
      newDiscussionActivity: ActivityResponse,
      message: NewMessageNotificationType
    ): AxiosResponse<GetActivitiesResponse> => {
      const filterActivities = (activities: ActivityResponse[]) => {
        return activities.filter(
          (activity) =>
            activity.story.id !== message.storyId &&
            activity.story.dateId !== message.storyDateId
        );
      };

      if (pageIndex === 0) {
        return {
          ...page,
          data: {
            ...page.data,
            activities: [
              newDiscussionActivity,
              ...filterActivities(page.data.activities),
            ],
          },
        };
      } else {
        return {
          ...page,
          data: {
            ...page.data,
            activities: [...filterActivities(page.data.activities)],
          },
        };
      }
    };

    if (!directActivitiesQueryData) {
      return;
    } else {
      const newMessageRecipientId =
        message.recipientType === RecipientType.Group
          ? message.recipientId
          : message.senderId;

      const foundCommentedStory: ActivityResponse | undefined =
        directActivitiesQueryData.pages
          .flatMap((page) => page.data.activities)
          .find(
            (activity) =>
              activity.story.id === message.storyId &&
              activity.story.dateId === message.storyDateId
          );

      const foundRecipient: RecipientsData | undefined =
        foundCommentedStory?.recipients.find(
          (recipient) =>
            recipient.id === newMessageRecipientId &&
            recipient.recipientType === message.recipientType
        );

      if (foundCommentedStory) {
        const newDiscussionActivity: ActivityResponse = {
          ...foundCommentedStory,
          newestMessage: {
            ...foundCommentedStory.newestMessage,
            content: message.content,
            contentType: message.contentType,
            id: message.id,
            isDeleted: false,
            isMyMessage: false,
            isRead: false,
            senderAvatar:
              message.recipientType === RecipientType.Group
                ? message.recipientAvatar
                : message.senderAvatar,
            senderId: newMessageRecipientId,
            senderName: message.senderName,
            senderUserName: message.senderUserName,
            timestamp: message.timestamp,
          },
          recipients: (() => {
            const updatedRecipients = foundCommentedStory.recipients.filter(
              (recipient) =>
                recipient.id !== newMessageRecipientId ||
                recipient.recipientType !== message.recipientType
            );
            return [
              {
                id: newMessageRecipientId,
                recipientType: message.recipientType,
                avatar:
                  message.recipientType === RecipientType.Group
                    ? message.recipientAvatar
                    : message.senderAvatar,
                name: message.senderName,
                userName: message.senderUserName,
                unreadMessagesCount: foundRecipient
                  ? foundRecipient.unreadMessagesCount + 1
                  : 1,
              },
              ...updatedRecipients,
            ];
          })(),
        };

        const updatedPages = directActivitiesQueryData?.pages.map(
          (page, index) =>
            updateActivityPages(page, index, newDiscussionActivity, message)
        );

        queryClient.cancelQueries("getDirectActivities");
        queryClient.setQueryData("getDirectActivities", {
          ...directActivitiesQueryData,
          pages: updatedPages,
        });
      } else {
        queryClient.invalidateQueries("getDirectActivities");
      }
    }
  };

  const handleNewMessageReceived = useCallback(
    (message: NewMessageNotificationType) => {
      updateDirectActivitiesCacheWithNewActivity(queryClient, message);
    },
    [queryClient]
  );

  useEffect(() => {
    const filter = {};
    subscribe(filter, handleNewMessageReceived);

    return () => unsubscribe(handleNewMessageReceived);
  }, [subscribe, unsubscribe, handleNewMessageReceived]);
};

export default useHandleNewPrivateDiscussionActivity;
