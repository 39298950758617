import { useMutation, useQueryClient } from "react-query";
import { apiClient } from "../../apiClient";
import { updateFeedCache } from "./functions/updateFeedCache";
import { updateSingleStoryCache } from "./functions/updateSingleStoryCache";
import { updateFilterStoryCache } from "./functions/updateFilterStoryCache";
import { handleErrorInPaginatedCache } from "./functions/handleErrorInPaginatedCache";

interface useSendStoryReactionProps {
  storyId: string;
  storyDateId: string;
  isFavorite: boolean;
  pageNumber?: number;
}

export const useSendStoryReaction = ({
  storyId,
  storyDateId,
  isFavorite,
  pageNumber,
}: useSendStoryReactionProps) => {
  const endpoint: string = `/usertimeline/${storyDateId}/${storyId}/${
    isFavorite ? "dislike" : "like"
  }`;
  const queryClient = useQueryClient();

  async function postReaction() {
    try {
      await apiClient.post(endpoint);
    } catch (error) {
      throw error;
    }
  }

  return useMutation(postReaction, {
    onMutate: async () => {
      await queryClient.cancelQueries(["content", `fetchStory${storyId}`]);

      const prevFeedStoryData = updateFeedCache({
        queryClient,
        pageNumber,
        storyId,
        updatedPropertyName: "Favorite",
        newValue: !isFavorite,
      });
      const prevSingleStoryData = updateSingleStoryCache({
        queryClient,
        storyId,
        updatedPropertyName: "Favorite",
        newValue: !isFavorite,
      });
      const prevFilterBookmarksCache = updateFilterStoryCache({
        queryClient,
        cacheType: "Bookmark",
        pageNumber,
        storyId,
        updatedPropertyName: "Favorite",
        newValue: !isFavorite,
      });
      const prevFilterFavoritesCache = updateFilterStoryCache({
        queryClient,
        cacheType: "Favorite",
        pageNumber,
        storyId,
        updatedPropertyName: "Favorite",
        newValue: !isFavorite,
      });

      return {
        prevFeedStoryData,
        prevSingleStoryData,
        prevFilterBookmarksCache,
        prevFilterFavoritesCache,
      };
    },
    onError: (error, variables, context) => {
      if (context?.prevFeedStoryData) {
        handleErrorInPaginatedCache({
          queryClient,
          queryKey: "content",
          pageNumber,
          storyId,
          context: context.prevFeedStoryData,
        });
      }
      if (context?.prevFilterBookmarksCache) {
        handleErrorInPaginatedCache({
          queryClient,
          queryKey: "filterBookmark",
          pageNumber,
          storyId,
          context: context.prevFilterBookmarksCache,
        });
      }
      if (context?.prevFilterFavoritesCache) {
        handleErrorInPaginatedCache({
          queryClient,
          queryKey: "filterFavorite",
          pageNumber,
          storyId,
          context: context.prevFilterFavoritesCache,
        });
      }
      if (context?.prevSingleStoryData) {
        queryClient.setQueryData(
          `fetchStory${storyId}`,
          () => context.prevSingleStoryData
        );
      }
    },
  });
};
