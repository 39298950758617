export function getTimeAgo(timestamp: string) {
  const currentTime = new Date();
  const commentTime = new Date(timestamp);

  if (isNaN(commentTime.getTime()) || isNaN(currentTime.getTime())) {
    return ""; //invalid timestamp
  }

  const timeDifference = Math.abs(
    currentTime.getTime() - commentTime.getTime()
  );
  const secondsAgo = Math.floor(timeDifference / 1000);
  const minutesAgo = Math.floor(secondsAgo / 60);
  const hoursAgo = Math.floor(minutesAgo / 60);
  const daysAgo = Math.floor(hoursAgo / 24);

  if (minutesAgo < 1) {
    return `${secondsAgo} second${secondsAgo !== 1 ? "s" : ""} ago`;
  } else if (minutesAgo < 60) {
    return `${minutesAgo} minute${minutesAgo !== 1 ? "s" : ""} ago`;
  } else if (hoursAgo < 24) {
    return `${hoursAgo} hour${hoursAgo !== 1 ? "s" : ""} ago`;
  } else {
    return `${daysAgo} day${daysAgo !== 1 ? "s" : ""} ago`;
  }
}
