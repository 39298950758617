import "./TwittCard.css";
import { TwitterTweetEmbed } from "react-twitter-embed";

export interface TwitterCardProps {
  TwittId: string;
  Id: number;
}
function TwittCard(props: TwitterCardProps) {
  return (
    <div className="twitt_card" key={props.TwittId}>
      <TwitterTweetEmbed tweetId={props.TwittId} />
    </div>
  );
}

export default TwittCard;
