import { createContext, ReactNode, useContext, useRef } from "react";
import { NewMessageNotificationType } from "../types/NewMessageNotificationType";
import { RecipientType } from "../types/RecipientType";

interface Filter {
  callerType?: RecipientType;
  callerId?: string;
  storyId?: string;
  storyDateId?: string;
}

interface Subscriber {
  filter: Filter;
  callback: (message: NewMessageNotificationType) => void;
}

interface NewMessageEventContextType {
  subscribe: (
    filter: Filter,
    callback: (message: NewMessageNotificationType) => void
  ) => void;
  unsubscribe: (
    callback: (message: NewMessageNotificationType) => void
  ) => void;
  emitMessage: (
    callerType: RecipientType,
    callerId: string,
    storyId: string,
    storyDateId: string,
    message: NewMessageNotificationType
  ) => void;
}

interface NewMessageEventContextProviderProps {
  children: ReactNode;
}
const NewMessageEventContext = createContext<NewMessageEventContextType | null>(
  null
);

export const NewMessageEventContextProvider = ({
  children,
}: NewMessageEventContextProviderProps) => {
  const subscribers = useRef<Subscriber[]>([]);

  const subscribe = (
    filter: Filter,
    callback: (message: NewMessageNotificationType) => void
  ) => {
    subscribers.current.push({ filter, callback });
  };

  const unsubscribe = (
    callback: (message: NewMessageNotificationType) => void
  ) => {
    subscribers.current = subscribers.current.filter(
      (subscriber) => subscriber.callback !== callback
    );
  };

  const emitMessage = (
    callerType: RecipientType,
    callerId: string,
    storyId: string,
    storyDateId: string,
    message: NewMessageNotificationType
  ) => {
    for (let i = 0; i < subscribers.current.length; i++) {
      const { filter, callback } = subscribers.current[i];

      if (
        (filter.callerType === callerType || filter.callerType === undefined) &&
        (filter.callerId === callerId || filter.callerId === undefined) &&
        (filter.storyId === storyId || filter.storyId === undefined) &&
        (filter.storyDateId === storyDateId || filter.storyDateId === undefined)
      ) {
        callback(message);
      }
    }
  };
  return (
    <NewMessageEventContext.Provider
      value={{ subscribe, unsubscribe, emitMessage }}
    >
      {children}
    </NewMessageEventContext.Provider>
  );
};

export function useNewMessageEventContext() {
  const context = useContext(NewMessageEventContext);
  if (!context) {
    throw new Error(
      "useNewMessageEventContext must be used within a NewMessageEventContextProvider"
    );
  }
  return context;
}
