import './HeadlineCard.css';

export interface HeadlineCardProps {
    Headline: string;
    CoverUrl: string;
    Id: number;
    DateId: string;
}

function HeadlineCard(props: HeadlineCardProps) {

    const year = props.DateId.substring(0, 4);
    const month = (props.DateId.substring(4, 6));
    const day = props.DateId.substring(6, 8);

    const monthNames: { [key: string]: string } = {
        "01": "January",
        "02": "February",
        "03": "March",
        "04": "April",
        "05": "May",
        "06": "June",
        "07": "July",
        "08": "August",
        "09": "September",
        "10": "October",
        "11": "November",
        "12": "December",
    };

    const monthName = monthNames[month] || "";

    return (
        <>
            <img src={props.CoverUrl} alt='headlineImage' className="headlineCard__img" />
            <div className='headlineCard__date'>{`${monthName} ${day}, ${year}`}</div>
            <div className='headlineCard__textBackground'><span>{props.Headline}</span></div>
        </>
    )
}

export default HeadlineCard;