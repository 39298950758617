import axios, { AxiosRequestConfig } from "axios";
import { loginRequest } from "./authConfig";
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from "./authConfig";

const msalInstance = new PublicClientApplication(msalConfig);
const apiClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL === undefined ? '' : process.env.REACT_APP_API_URL
});

apiClient.interceptors.request.use(
    async (config: AxiosRequestConfig) => {
        config.headers = config.headers || {};
        if (!config.headers['Content-Type']) {
            config.headers['Content-Type'] = 'application/json';
        }
        // config.headers['Authorization'] = 'Bearer ' + '[enter your token here]';


        const account = msalInstance.getAllAccounts()[0];
        const request = {
            ...loginRequest,
            account: account
        };
        if (account) {
            try {
                const accessTokenResponse = await msalInstance.acquireTokenSilent(request);
                if (!config.headers['Authorization']) {
                    config.headers['Authorization'] = 'Bearer ' + accessTokenResponse.accessToken;
                }
            } catch (error) {
                console.error(error);
            }
        }
        return config;
    },
    error => {
        Promise.reject(error)
    });

export { msalInstance, apiClient };