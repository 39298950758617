import "./ShareStoryPopUp.css";
import { useState, useRef, useEffect } from "react";


interface ShareStoryPopUpProps {
    trigger: boolean;
    showPopUp: React.Dispatch<React.SetStateAction<boolean>>;
    newsId: string;
    newsDate: string;
}

function ShareStoryPopUp(props: ShareStoryPopUpProps) {

    const host = window.location.host;

    const linkValue = `${host}/News/${props.newsDate}/${props.newsId}`;
    const [isCopied, setIsCopied] = useState<boolean>(false);

    const sharePopUp = useRef<HTMLDivElement>(null);
    const messageRef = useRef<HTMLDivElement>(null);

    async function copyTextToClipboard(text: string) {
        try {
            await navigator.clipboard.writeText(text);
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 3000);
        } catch (error) {
            console.error('Failed to copy:', error);
        }
    }

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (props.trigger === true && messageRef.current && !messageRef.current.contains(event?.target as Node)) {
                props.showPopUp(false);
            }
        }

        const currentSharePopUp = sharePopUp.current;

        if (currentSharePopUp) {
            currentSharePopUp.addEventListener('click', handleClickOutside);
        }

        return () => {
            if (currentSharePopUp) {
                currentSharePopUp.removeEventListener('click', handleClickOutside);
            }
        }

    }, [props])

    return (
        props.trigger ?
            <div ref={sharePopUp} className={`shareStories__background ${props.trigger ? 'openAnimation' : 'closeAnimation'}`} >
                <div ref={messageRef} className="shareStories__container">
                    <div>
                        <button className="share__buttons" onClick={() => copyTextToClipboard(linkValue)}>{isCopied ? 'Copied!' : 'Copy'}</button>
                        <button className="share__buttons" onClick={() => props.showPopUp(false)}>Cancel</button>
                    </div>
                </div>
            </div >
            : null
    )
}

export default ShareStoryPopUp