import Icon from "@mui/material/Icon";
import styles from "./DialogWindow.module.css";
import { ReactElement } from "react";

interface DialogWindowProps {
  question?: string;
  options: OptionsProps[];
  extraButtonName?: string;
  extraButtonCallback?: () => void;
}

interface OptionsProps {
  icon?: string;
  optionName: string | ReactElement;
  fontColor?: string;
  handleClick: () => void;
  isDisabled?: boolean;
}

function DialogWindow({
  question,
  options,
  extraButtonCallback,
  extraButtonName,
}: DialogWindowProps) {
  return (
    <div className={styles.container}>
      <div className={styles.dialogBox}>
        {question && <div className={styles.question}>{question}</div>}
        {options.map((option, index) => (
          <button
            key={index}
            style={{ color: option.fontColor ?? "black" }}
            className={styles.button}
            onClick={() => option.handleClick()}
            disabled={option.isDisabled ?? false}
          >
            {option.icon && <Icon className={styles.icon}>{option.icon}</Icon>}
            <div> {option.optionName}</div>
          </button>
        ))}
      </div>
      {extraButtonCallback && extraButtonName && (
        <button
          className={styles.extraButton}
          onClick={() => extraButtonCallback()}
        >
          {extraButtonName}
        </button>
      )}
    </div>
  );
}

export default DialogWindow;
