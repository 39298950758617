import { ReactNode } from "react";
import styles from "./CommentActivity.module.css";
import { Link } from "react-router-dom";
import { DiscussionType } from "../types/DiscussionType";

interface CommentActivityProps {
  imgSrc: string;
  headline: string;
  newestMessage: string;
  storyId: string;
  storyDateId: string;
  newestMessageRecipientId: string;
  newestMessageRecipientType: DiscussionType;
  children?: ReactNode;
}

function CommentActivity(props: CommentActivityProps) {
  return (
    <div className={styles.activityBox}>
      <Link to={`/News/${props.storyDateId}/${props.storyId}`}>
        <div className={styles.coverContainer}>
          <img src={props.imgSrc} alt="headline" />
        </div>
      </Link>
      <div className={styles.rightContainer}>
        <Link
          to={`/News/${props.storyDateId}/${props.storyId}`}
          className={styles.link}
        >
          <div className={styles.headline}>{props.headline}</div>
        </Link>
        <Link
          to={`/News/${props.storyDateId}/${props.storyId}/true/${props.newestMessageRecipientId}/${props.newestMessageRecipientType}`}
          className={styles.link}
        >
          <div className={styles.newestMessage}>{props.newestMessage}</div>
        </Link>
        <div className={styles.dot}>
          {/* <div className={styles.messagesCount}>
            <span>1</span>
          </div> */}
        </div>
        <div className={styles.avatarsContainer}>{props.children}</div>
      </div>
    </div>
  );
}

export default CommentActivity;
