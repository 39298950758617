import styles from "./GroupDetailsHeader.module.css";
import MiniAvatar from "../../components/elements/MiniAvatar";
import { ReactComponent as ArrowBack } from "../../components/icons/ArrowBackIcon.svg";
import { ReactComponent as MoreIcon } from "../../components/icons/MoreIcon.svg";
import { Link, useNavigate } from "react-router-dom";
import { RecipientType } from "../../discussions/types/RecipientType";
import DialogWindow from "../../components/DialogWindow";
import { useState } from "react";
import EditGroupNameDialog from "./EditGroupNameDialog";
import { toast, ToastContainer } from "react-toastify";
import { useQueryClient } from "react-query";
import {
  errorToastOptions,
  successToastOptions,
} from "../../components/toastOptions";
import { CircularProgress } from "@mui/material";
import { useLeaveGroup } from "../hooks/useLeaveGroup";
import { useChangeGroupAvatar } from "../hooks/useChangeGroupAvatar";
import AvatarCropperModal from "../../components/AvatarCropperModal";
import { useAvatarUpload } from "../../customHooks/useAvatarUpload";

interface GroupDetailsHeaderProps {
  groupId: string;
  groupName: string;
  isOwner: boolean;
  avatar: string;
  membersCount: number;
}

function GroupDetailsHeader(props: GroupDetailsHeaderProps) {
  const [groupOptionsOpen, setGroupOptionsOpen] = useState<boolean>(false);
  const [editNameWindowOpen, setEditNameWindowOpen] = useState<boolean>(false);
  const [leaveGroupDialogOpen, setLeaveGroupDialogOpen] =
    useState<boolean>(false);

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const triggerEditGroupNameView = () => {
    setEditNameWindowOpen(true);
    setGroupOptionsOpen(false);
  };

  const triggerLeaveGroupDialog = () => {
    setLeaveGroupDialogOpen(true);
    setGroupOptionsOpen(false);
  };

  const triggerChangeGroupAvatar = () => {
    handleAddPic();
    setGroupOptionsOpen(false);
  };

  const { mutate: leaveGroup, isLoading: isLeavingGroup } = useLeaveGroup();

  const { mutate: changeGroupAvatar, isLoading: isChangingGroupAvatar } =
    useChangeGroupAvatar(props.groupId);

  const {
    inputRef,
    croppedPicRef,
    avatarSelectorModalOpen,
    newAvatarSrc,
    handleAddPic,
    handleImgChange,
    setCanvas,
    setAvatarSelectorModalOpen,
  } = useAvatarUpload({
    mutateFn: changeGroupAvatar,
    invalidateQueryKey: ["group", props.groupId],
  });

  const handleLeaveGroup = (groupId: string, isOwner: boolean) => {
    if (isOwner) {
      toast.error(
        "You are currently the owner of this group. Before you can leave, please transfer ownership to another member.",
        errorToastOptions
      );
    } else {
      leaveGroup(groupId, {
        onSuccess: () => {
          setLeaveGroupDialogOpen(false);
          toast.success(
            `You left group ${props.groupName}`,
            successToastOptions
          );
          queryClient.invalidateQueries([`groups`, ""]);

          setTimeout(() => navigate("/Community/Groups"), 2000);
        },
        onError: (error) => {
          toast.error(
            "Something went wrong. You couldn't leave the group",
            errorToastOptions
          );
        },
      });
    }
  };

  return (
    <>
      <ToastContainer />
      <input
        type="file"
        ref={inputRef}
        accept="image/png, image/jpeg, image/jpg"
        onChange={handleImgChange}
        style={{ display: "none" }}
      />
      <AvatarCropperModal
        newAvatarSrc={newAvatarSrc}
        modalOpen={avatarSelectorModalOpen}
        croppedPicRef={croppedPicRef}
        setModalOpen={setAvatarSelectorModalOpen}
        handleSavePicture={setCanvas}
        isLoading={isChangingGroupAvatar}
      />
      <div className={styles.container}>
        <div className={styles.icons}>
          <Link to="/Community/Groups">
            <ArrowBack />
          </Link>
        </div>
        <div className={styles.center}>
          <div className={styles.avatar}>
            <MiniAvatar
              src={props.avatar}
              height="50px"
              name={props.groupName}
              characterType={RecipientType.Group}
            />
          </div>
          <div className={styles.groupName}>{props.groupName}</div>
          <div className={styles.membersCount}>{`${props.membersCount}${
            props.membersCount === 1 ? " User" : " Users"
          }`}</div>
        </div>
        <div className={styles.icons}>
          <MoreIcon onClick={() => setGroupOptionsOpen(true)} />
        </div>
      </div>
      {groupOptionsOpen && (
        <DialogWindow
          options={[
            ...(props.isOwner
              ? [
                  {
                    icon: "mode_edit",
                    optionName: "Change group name",
                    handleClick: () => triggerEditGroupNameView(),
                  },
                  {
                    icon: "photo_camera",
                    optionName: "Change group avatar",
                    handleClick: () => triggerChangeGroupAvatar(),
                  },
                ]
              : []),
            {
              icon: "logout",
              optionName: "Leave the group",
              fontColor: "var(--red-font)",
              handleClick: () => triggerLeaveGroupDialog(),
            },
            ...(props.isOwner
              ? [
                  // {
                  //   icon: "close",
                  //   optionName: "Delete the group",
                  //   fontColor: "var(--red-font)",
                  //   handleClick: () => console.log("delete the group"),
                  // },
                ]
              : []),
          ]}
          extraButtonCallback={() => setGroupOptionsOpen(false)}
          extraButtonName="Close"
          key="mainMenuDialog"
        />
      )}
      {editNameWindowOpen ? (
        <EditGroupNameDialog
          groupId={props.groupId}
          currentGroupName={props.groupName}
          setDialogWindowOpen={setEditNameWindowOpen}
        />
      ) : leaveGroupDialogOpen ? (
        <DialogWindow
          question={`Are you sure that you want to leave the group ${props.groupName}? `}
          options={[
            {
              optionName: isLeavingGroup ? (
                <CircularProgress size={14} />
              ) : (
                "Leave"
              ),
              handleClick: () => handleLeaveGroup(props.groupId, props.isOwner),
              fontColor: "var(--red-font)",
            },
            {
              optionName: "Cancel",
              handleClick: () => setLeaveGroupDialogOpen(false),
            },
          ]}
          key="leaveGroupDialog"
        />
      ) : null}
    </>
  );
}

export default GroupDetailsHeader;
