import styles from "./Button.module.css";

interface ButtonProps {
  buttonText: string;
  handleClick: () => void;
  height?: string;
  width?: string;
  disabled?: boolean;
}

function Button(props: ButtonProps) {
  return (
    <button
      className={styles.button}
      onClick={props.handleClick}
      disabled={props.disabled}
      style={{
        ...(props.height && { height: props.height }),
        ...(props.width && { width: props.width }),
      }}
    >
      {props.buttonText}
    </button>
  );
}

export default Button;
