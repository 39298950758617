import { useEffect } from "react";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { msalInstance } from "../../apiClient";
import { loginRequest } from "../../authConfig";
import { useNewNotificationsContext } from "../context/newNotificationContext";
import { NotificationType } from "../types/NotificationType";
import { SupportNotificationDataType } from "../types/SupportNotificationDataType";
import { useNewMessageEventContext } from "../../discussions/context/NewMessageEventContext";
import { NewMessageNotificationType } from "../../discussions/types/NewMessageNotificationType";
import { RecipientType } from "../../discussions/types/RecipientType";

export const useSignalRConnection = (isAuthenticated: boolean) => {
  const { setNewNotifications, setUnreadNotificationsCount } =
    useNewNotificationsContext();
  const { emitMessage } = useNewMessageEventContext();

  useEffect(() => {
    let connection: HubConnection | null = null;

    const setupSignalRConnection = async () => {
      if (!isAuthenticated) return;
      const account = msalInstance.getAllAccounts()[0];
      const request = {
        ...loginRequest,
        account: account,
      };
      const accessTokenResponse = await msalInstance.acquireTokenSilent(
        request
      );
      const baseUrl =
        process.env.REACT_APP_API_URL === undefined
          ? ""
          : process.env.REACT_APP_API_URL;
      connection = new HubConnectionBuilder()
        .withUrl(`${baseUrl}notifications`, {
          accessTokenFactory: () => accessTokenResponse.accessToken,
        })
        .withAutomaticReconnect()
        .build();

      connection.on(
        "onMessageReceived",
        (newMessage: NotificationType<NewMessageNotificationType>) => {
          const callerType = newMessage.data.recipientType;
          const callerId =
            callerType === RecipientType.Group
              ? newMessage.data.recipientId
              : newMessage.data.senderId;
          const storyId = newMessage.data.storyId;
          const storyDateId = newMessage.data.storyDateId;

          emitMessage(
            callerType,
            callerId,
            storyId,
            storyDateId,
            newMessage.data
          );
        }
      );

      connection.on(
        "onSupportedBy",
        (x: NotificationType<SupportNotificationDataType>) => {
          setNewNotifications((prevNotifications) => [x, ...prevNotifications]);
          setUnreadNotificationsCount((prevCount) => prevCount + 1);
        }
      );

      try {
        await connection.start();
        console.log("SignalR Connection successful.");
      } catch (err) {
        console.error("SignalR Connection failed: ", err);
      }
    };

    setupSignalRConnection();

    return () => {
      connection?.stop().then(() => console.log("SignalR Connection stopped."));
    };
  }, [
    isAuthenticated,
    setNewNotifications,
    setUnreadNotificationsCount,
    emitMessage,
  ]);
};
