import { QueryClient } from "react-query";
import { ContentResponse } from "../../types/ContentResponse";
import { FilterTimelineApiResponse } from "../useFilterTimeline";
import { FilterStoriesType } from "../../types/FilterStoriesType";

interface UpdateFilterStoryCacheProps {
  queryClient: QueryClient;
  cacheType: FilterStoriesType;
  storyId: string;
  updatedPropertyName: FilterStoriesType;
  newValue: boolean;
  pageNumber?: number;
}

export const updateFilterStoryCache = ({
  queryClient,
  cacheType,
  pageNumber,
  storyId,
  updatedPropertyName,
  newValue,
}: UpdateFilterStoryCacheProps): ContentResponse | null => {
  const filterTimelineData: { pages: FilterTimelineApiResponse[] } | undefined =
    queryClient.getQueryData(`filter${cacheType}`);

  if (filterTimelineData && pageNumber !== undefined) {
    const pageToUpdate = filterTimelineData.pages[pageNumber];
    const storyToUpdateIndex = pageToUpdate.Stories.findIndex(
      (story) => story.Id === storyId
    );

    if (storyToUpdateIndex !== -1) {
      const prevFilterStoryCache: ContentResponse = {
        ...pageToUpdate.Stories[storyToUpdateIndex],
      };

      queryClient.cancelQueries(`filter${cacheType}`);

      queryClient.setQueryData<
        { pages: FilterTimelineApiResponse[] } | undefined
      >(
        `filter${cacheType}`,
        (data: { pages: FilterTimelineApiResponse[] } | undefined) => {
          if (!data) return data;

          const newFeedStoryData = { ...data };

          if (newFeedStoryData.pages[pageNumber]) {
            if (updatedPropertyName === "Favorite") {
              newFeedStoryData.pages[pageNumber].Stories[storyToUpdateIndex] = {
                ...prevFilterStoryCache,
                Favorite: newValue,
                Likes: newValue
                  ? prevFilterStoryCache.Likes + 1
                  : prevFilterStoryCache.Likes - 1,
              };
            } else {
              newFeedStoryData.pages[pageNumber].Stories[storyToUpdateIndex] = {
                ...prevFilterStoryCache,
                Bookmark: newValue,
              };
            }
          }
          return newFeedStoryData;
        }
      );

      return prevFilterStoryCache;
    }
    return null;
  }
  return null;
};
