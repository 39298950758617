import globalStyles from "../components/global.module.css";
import "./Feed.css";
import ContentCard from "./ContentCard";
import { useParams } from "react-router-dom";
import HeaderFeed from "./HeaderFeed";
import useCalculateContentHeight from "../customHooks/useCalculateContentHeight";
import CircularProgress from "@mui/material/CircularProgress";
import cardsRenderer from "./cardsRenderer";
import { useFetchSingleStory } from "./hooks/useFetchSingleStory";
import axios from "axios";

function SingleStory() {
  const { newsDate, newsId } = useParams();
  const contentHeight = useCalculateContentHeight();

  const { data, isLoading, isError, error } = useFetchSingleStory({
    storyDateId: newsDate,
    storyId: newsId,
  });

  return (
    <div style={{ height: contentHeight }}>
      <HeaderFeed />
      {isLoading ? (
        <div className={globalStyles.centralMessage}>
          <CircularProgress />
        </div>
      ) : axios.isAxiosError(error) && error.response?.status === 404 ? (
        <div className={globalStyles.centralMessage}>
          News you are trying to access does not exist
        </div>
      ) : isError ? (
        <div className={globalStyles.messageInfo}>
          <p>Something went wrong</p>
        </div>
      ) : (
        <>
          {data ? (
            <div
              className="content_container"
              key={`content-container-${data.Id}`}
            >
              <ContentCard
                cardId={data.Id}
                dateId={data.DateId}
                key={`content-card-${data.Id}`}
                favorite={data.Favorite}
                bookmark={data.Bookmark}
                likes={data.Likes}
              >
                {cardsRenderer(data.Cards, data.Id, data.DateId, data.Source)}
              </ContentCard>
            </div>
          ) : null}
        </>
      )}
    </div>
  );
}

export default SingleStory;
