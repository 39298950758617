import { useMutation } from "react-query";
import { apiClient } from "../../apiClient";

export const useChangeUserAvatar = () => {
  async function postAvatar(formData: FormData) {
    try {
      await apiClient.post("userpreferences/avatar", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (error) {
      console.error("Error changing user avatar", error);
      throw error;
    }
  }

  return useMutation(postAvatar);
};
