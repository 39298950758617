import { useState, useRef, ChangeEvent } from "react";
import AvatarEditor from "react-avatar-editor";
import { toast } from "react-toastify";
import {
  successToastOptions,
  errorToastOptions,
} from "../components/toastOptions";
import { useQueryClient } from "react-query";

interface useAvatarUploadProps {
  mutateFn: any;
  invalidateQueryKey: string | string[];
}
export const useAvatarUpload = ({
  mutateFn,
  invalidateQueryKey,
}: useAvatarUploadProps) => {
  const [avatarSelectorModalOpen, setAvatarSelectorModalOpen] = useState(false);
  const [newAvatarSrc, setNewAvatarSrc] = useState<string>();
  const inputRef = useRef<HTMLInputElement>(null);
  const croppedPicRef = useRef<AvatarEditor | null>(null);
  const queryClient = useQueryClient();

  const handleAddPic = () => {
    if (inputRef.current) {
      inputRef.current.value = "";
    }
    inputRef.current?.click();
  };

  const handleImgChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setNewAvatarSrc(URL.createObjectURL(event.target.files[0]));
      setAvatarSelectorModalOpen(true);
    }
  };

  const handleUploadAvatar = async (): Promise<void> => {
    if (croppedPicRef.current) {
      const canvas: HTMLCanvasElement =
        croppedPicRef.current.getImageScaledToCanvas();
      const blob = await new Promise<Blob>((resolve) => {
        canvas.toBlob((blob) => {
          if (blob) resolve(blob);
          else throw new Error("Failed to convert canvas to Blob.");
        });
      });

      const formData = new FormData();
      formData.append("file", blob);

      mutateFn(formData, {
        onSuccess: () => {
          toast.success(`Avatar updated successfully`, successToastOptions);
          queryClient.invalidateQueries(invalidateQueryKey);
          setAvatarSelectorModalOpen(false);
        },
        onError: () => {
          toast.error(
            "Something went wrong. We couldn't update the avatar.",
            errorToastOptions
          );
        },
      });
    }
  };

  return {
    inputRef,
    croppedPicRef,
    avatarSelectorModalOpen,
    newAvatarSrc,
    handleAddPic,
    handleImgChange,
    setCanvas: handleUploadAvatar,
    setAvatarSelectorModalOpen,
  };
};
